import { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { MediaSeatWidget, PurchaseData, Ticket, datosCompra } from '../../../types/types';
import { purchaseSell, purchaseDeleteDetail } from '../../../api/purchase';
import { fetchDetalleCompra } from '../../../reducer/events-reducer';
import { eventoSeleccionado } from '../../../types/types';
import Spinner from '../Spinner';
import {
    Row,
    Col,
    Button,
    Modal, ModalHeader, ModalBody,
} from 'reactstrap';

export type configObjectEventos = {
    eventsReducer: {
        datosCompraPasoDos: PurchaseData;
        detalleCompra: datosCompra;
        eventoSeleccionado: eventoSeleccionado;
    }
}

const CompraEventoSingular = ({ datosCompraPasoDos, fetchDetalleCompra, detalleCompra, eventoSeleccionado }: { datosCompraPasoDos: PurchaseData, fetchDetalleCompra: () => any, detalleCompra: datosCompra, eventoSeleccionado: eventoSeleccionado }) => {
    const [lugaresAgregados, setLugaresAgregados] = useState(0);
    const [disabledBts, setDisabledBts] = useState(false)
    const [disableLessBt, setdisableLessBt] = useState(true);
    const [disableMoreBt, setdisableMoreBt] = useState(false);
    const [modal, setModal] = useState<boolean>(false);
    const [errorMessage, seterrorMessage] = useState();
    const [mediaSeatWidget, setMediaSeatWidget] = useState<MediaSeatWidget | null>(null);
    const [priceResume, setPriceResume] = useState<string>("");
    const [lastTickets, setLastTickets] = useState<Ticket[]>([]);

    const toggle = () => {
        setModal(!modal);
    }
    const handlePurchase = async (action: () => Promise<any>) => {
        setDisabledBts(true);
        const res = await action();
        if (res.result === true) {
            await fetchDetalleCompra();
            setTimeout(() => {
                setDisabledBts(false);
            }, 500);
        } else {
            setDisabledBts(false);
            
            let message = res.message;
            if (message.includes('NO_ACEPTA_MAS_VENTAS')) {
                message = "No hay más Lugares Disponibles";
            }

            seterrorMessage(message);
            toggle();
            setdisableMoreBt(true);
        }
    };

    const plusOneHandler = async (code: string) => {
        await handlePurchase(() => purchaseSell(code));
    };

    const lessOneHandler = async () => {

        await handlePurchase(() => purchaseDeleteDetail(null));
    };

    function difference<T>(tickets: Ticket[], tickets2: Ticket[]): Ticket[] {
        const idsIntickets2 = tickets2.map(ticket => ticket.detailCode);
        return tickets.filter(ticket => !idsIntickets2.includes(ticket.detailCode));
    }

    useEffect(() => {
        setPriceResume(datosCompraPasoDos.sectorList[0].pricesResume);
        if (detalleCompra) {
            setLugaresAgregados(detalleCompra.tickets.length)
            setMediaSeatWidget(detalleCompra.mediaSeatWidget);
            
            if(detalleCompra.tickets.length && lastTickets.length){

                const arrayTicketsAdded = difference(detalleCompra.tickets, lastTickets);
                const arrayTicketsRemoved = difference(lastTickets, detalleCompra.tickets);
                
                let lastTicket = detalleCompra.tickets.at(-1);
                if(arrayTicketsRemoved.length) {
                    lastTicket = arrayTicketsRemoved.at(-1);
                }

                if(arrayTicketsAdded.length) {
                    lastTicket = arrayTicketsAdded.at(-1);
                }

                if(lastTicket){
                    setPriceResume(`${lastTicket.item}: ${lastTicket.price}`);
                }
            }
            setLastTickets(detalleCompra.tickets);
        }
    }, [detalleCompra, datosCompraPasoDos])

    useEffect(() => {
        fetchDetalleCompra();
    }, [fetchDetalleCompra])

    useEffect(() => {
        if (lugaresAgregados === 0) {
            setdisableLessBt(true)
        } else {
            setdisableLessBt(false)
        }
        if (disableMoreBt) {
            setdisableMoreBt(false)
        }
    }, [lugaresAgregados, disableMoreBt])

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader toggle={toggle}>AVISO</ModalHeader>
                <ModalBody>
                    <p className='text-center error-message'>{errorMessage}</p>
                    <div className='d-flext justify-content-center p-0 mb-2'>
                        <Row className='text-center'>
                            <Col md={12} className='pb-2 pt-2'>
                                <Button className='cancel-bt ps-5 pe-5' onClick={()=>{ toggle(); }}>CERRAR </Button>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
            <Col>
                <Row>
                    {!mediaSeatWidget && eventoSeleccionado.image && <Col> <img src={eventoSeleccionado.image} alt='' className='img-fluid' /></Col>}
                    {mediaSeatWidget && <Col> <img src={mediaSeatWidget.urlDesktop} alt='' className='img-fluid' /></Col>}
                    <Col>
                        <h4 className='subtitles text-center'>Agrega Tus Lugares</h4>
                        {datosCompraPasoDos.sectorList.map((sector, index) => {
                            
                            return (<Fragment key={index}>
                                <p className='text-center'><strong>{sector.name}</strong><br />
                                    {priceResume}</p>
                                <Row>
                                    <Col className='d-flex justify-content-center add-places'>
                                        <Button className='rounded-circle me-3' onClick={lessOneHandler} disabled={disabledBts || disableLessBt}>
                                            {disabledBts ? <Spinner className='spinnerbutton' /> : '-1'}
                                        </Button>
                                        <p className='pt-1'>{lugaresAgregados}</p>
                                        <Button className='rounded-circle ms-3' onClick={() => { plusOneHandler(sector.code) }} disabled={disabledBts || disableMoreBt}>
                                            {disabledBts ? <Spinner className='spinnerbutton' /> : '+1'}
                                        </Button>
                                    </Col>
                                </Row>
                            </Fragment>)
                        })}
                    </Col>
                </Row>
            </Col>
        </>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        datosCompraPasoDos: state.eventsReducer.datosCompraPasoDos,
        detalleCompra: state.eventsReducer.detalleCompra,
        eventoSeleccionado: state.eventsReducer.eventoSeleccionado,
    }
}
export default connect(
    mapStateToProps,
    { fetchDetalleCompra }
)(CompraEventoSingular);
