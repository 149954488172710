import { useState } from 'react';
import {
    Row,
    Col,
    Modal, Button, ModalHeader, ModalBody,
} from 'reactstrap';
import { mediaItem } from '../../../types/types';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import '../../sass/evento_seleccionado/info_general.scss'

const UbicacionFotos = (props: { lugar: string, address: string | null, location: string, map: string | null, medialist: mediaItem[]/* , duration: string, classification: string */ }) => {

    const [modal, setModal] = useState<boolean>(false);
    const [contenidoModalMapa, setContenidoModalMapa] = useState<boolean>(false)
    const toggle = () => {
        setModal(!modal);
    }

    const handleVerMapa = () => {
        setContenidoModalMapa(true);
        setModal(true)
    }

    const handleVerImagenes = () => {
        setContenidoModalMapa(false);
        setModal(true)
    }
    const ModalclassName = classNames('evento-seleccionado-modal modal-lg', {
        mapa: contenidoModalMapa,
        fotos: !contenidoModalMapa
    });

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered={true} className={ModalclassName}>
                <ModalHeader toggle={toggle}>{contenidoModalMapa ? <>UBICACION</> : <>FOTOS</>}</ModalHeader>
                <ModalBody>
                    {contenidoModalMapa && <div className='mapa-wrapper' dangerouslySetInnerHTML={{ __html: props.map! }}></div>}
                    {!contenidoModalMapa && <Carousel showArrows={true} showThumbs={false} showStatus={false} dynamicHeight={true}>
                        {props.medialist.map((media, index) => {
                            return (
                                <div key={index}>
                                    <img src={isMobile ? media.url_mobile : media.url_large} alt={media.title} />
                                </div>
                            )
                        })}
                    </Carousel>}
                </ModalBody>
            </Modal>
            {
                <Row className='info-general'>
                    <Col className='d-flex align-items-center'>
                        <LocationOnOutlinedIcon />
                        <div className='ms-2 align-items-center' style={{"flex":"1"}}>
                            <p><b>{props.lugar}</b> <br/><b>{props.location}</b> <br/>{props.address}</p>
                        </div>
                    </Col>
                    <div className='d-flex justify-content-center'>
                        <div className='d-flex justify-content-around buttons-wrapper'>
                            {props.map !== null && props.map !== "" && <Button outline onClick={handleVerMapa}>Ubicación</Button>}
                            {props.medialist.length > 0 && <Button outline onClick={handleVerImagenes} className='btn-imagenes-lugar'>Fotos</Button>}
                        </div>
                    </div>
            </Row>
            }
        </>
    )
};

export default UbicacionFotos;