import { useEffect, useState } from 'react';
import Spinner from '../components/Spinner';
import { executeTransfer } from '../../api/transfer';
import { Col, Container, Row } from 'reactstrap';


const SmartTicketConfirmaTransferencia = () => {
    
    const [transferSuccess, setTransferSuscces] = useState<any | null>(null);
    const [errorMessage, setErrorMessage] = useState<any | null>(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        (async () => {
            if(code) {
                try {
                    const response = await executeTransfer(code);
                    if (response) {
                        setTransferSuscces(response);
                    }    
                } catch (error: any) {
                    setErrorMessage(error!.message)
                }
                
            }
        })()
    }, [])

    return (
        <Container className='text-center pay-success'>
            {transferSuccess && !errorMessage &&
                <>
                    <Row>
                        <Col xs={{ size: 4, offset: 4 }} className='mt-4'>
                            <img src="/images/confirmado.svg" alt="icono confirmado" className='img-fluid logo-confirmado' />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='text-start'>
                                <p className='title text_3 mt-4 mb-0'>Listo!</p>
                                <p className='title text_3 mt-0'>Confirmaste la Transferencia y ya tienes tus Entradas!</p>
                                <div>
                                    <p><b>Descripción de la Transferencia</b></p>
                                    <p className='m-0 p-0'>Generada por: {transferSuccess.originUserName}</p>
                                    <p className='m-0 p-0'>Fecha y hora de Generación: {transferSuccess.generationDate}</p>
                                    <p className='m-0 p-0'>Evento: <span dangerouslySetInnerHTML={{__html: transferSuccess.eventName}}></span></p>
                                    <p className='m-0 p-0'>Fecha y Hora del Evento: {transferSuccess.eventDate}</p>
                                    <p className='m-0 p-0'>Cant. de Tickets: {transferSuccess.ticketsQuantity}</p>
                                    <p className='m-0 p-0'>Ubicación: {transferSuccess.eventLocation}</p>
                                </div>
                                <br />
                                <p>Para acceder a tus entradas deberás tener instalada en tu celu la App de Entradaweb QR y Smart Ticket.</p>
                                <p className='m-0 p-0'>Si no la tienes aún instalada las puedes bajar de GooglePlay o AppStore</p>
                                <br />
                            </div>
                        </Col>
                    </Row>
            </>}
            {!transferSuccess && !errorMessage &&
            <div className='vh-100'>
                <h1 className='mt-5 text_3 general-title text-center'>Estamos procesando</h1>
                <h1 className='text_3 general-title text-center'>tu confirmación de transferencia...</h1>
                <h1 className='text_3 general-title text-center'>Aguarda unos segundos...</h1>
                <Spinner className='text-center' style={{"top":"40%", "left":"40%"}}/>
            </div>}
            {errorMessage && 
            <>
                <Row>
                    <Col xs={{ size: 4, offset: 4 }} className='mt-4'>
                        <img src="/images/icono-busqueda-01.svg" alt="icono de busqueda sin resultados" className='img-fluid' />
                    </Col>
                </Row>
                <Row>
                    <Col><h1 className='text_3 fs-22 pt-3'>¡No encontramos ningún resultado!</h1></Col>
                </Row>
                <Row>
                    <Col className='text-start ps-4 pe-4 fs-12 ps-5 pt-2' style={{ "lineHeight": "22px" }}>
                        <p>{errorMessage}</p>
                    </Col>
                </Row>
            </>
            }
        </Container>
    );
};

export default SmartTicketConfirmaTransferencia;