import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import {  combineReducers } from 'redux';
import configReducer from './config-reducer';
import eventsReducer from './events-reducer';

const objReducer = {
  configReducer:configReducer,
  eventsReducer:eventsReducer
    }
const mainReducer = combineReducers(objReducer);

export const store = configureStore({
  reducer: mainReducer,middleware: [
    ...getDefaultMiddleware({
        serializableCheck: false
    }),
  ]
})



export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


