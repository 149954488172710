import React, { useState, Dispatch } from 'react';
import { decidirPayWay } from '../../../api/purchase';
import { connect } from 'react-redux';
import { installmentData } from '../../../types/types';
import { useNavigate } from 'react-router-dom';
import TarjetaDeCredito from './TarjetaDeCredito';
import {
  Row,
  Col,
  Button,
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import { sellerDecidirPayWay } from '../../../api/transfer';

const DecidirCom =  ({cardNumber, installments, cardNumberHandle, publicKey, purchaseCode}:{cardNumber:string, installments:installmentData[], cardNumberHandle:Dispatch<string>, publicKey: string | null, purchaseCode: string | null}) => {
    const navigate = useNavigate();
    const [cuotas, setCuotas] = useState(1);
    const [buttonState, setButtonState] = useState({text:'PAGAR', enable:true});

    const [formState, setFormState] = useState({
        cardNumber: cardNumber,
        expirationMonth: '',
        expirationYear: '',
        securityCode: '',
        HOLDER_NAME: '',
        DOCUMENT: '',
        EMAIL: '',
    });
    const [modal, setModal] = useState<boolean>(false);
    const [resetPurchase, setResetPurchase] = useState<boolean>(false);
    const [errorMessage, seterrorMessage] = useState();
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        if(value.length > Number(event.target.getAttribute('max'))){
         return
        }
        let str: any;
        if (name === 'cardNumber' || name === 'expirationMonth' || name === 'expirationYear' || name === 'DOCUMENT') {
            var pattern = /^([0-9]+)?$/;
            if (pattern.test(value)) {
              str = value;
            } else {
              return;
            }
          } else {
            str = value;
          }
        setFormState((prevState) => ({
            ...prevState,
            [name]: str,
        }));
    };
    const toggle = () => {
      setModal(!modal);
      
      if(modal && resetPurchase) {
        localStorage.removeItem('token');
        navigate(`/`)
      }
    }
    const submitHandler = async () => {
        const publicApiKey = publicKey ? publicKey : "e1f02051bf174e06a79ae7f5699787c1";
        const urlSandbox = "https://live.decidir.com/api/v2";
        const decidir: any = new (window as any).Decidir(urlSandbox);

        decidir.setPublishableKey(publicApiKey);
        decidir.setTimeout(5000);
        setButtonState({text:"Procesando...", enable:false});
        var form = document.querySelector("#card-container");

        const sdkResponseHandler = (status: any, response: any) => {
            if (status !== 200 && status !== 201) {
                setButtonState({text:"PAGAR", enable:true});
                
                const errorMessage: any = "Ingreso Incorrecto. Revise los Datos de la Tarjeta";
                seterrorMessage(errorMessage);
                toggle();
                
                //Manejo de error: Ver Respuesta de Error
                //...codigo...
            } else {
                if(purchaseCode) {
                  sellerDecidirPayWay(response.bin,response.id, response.cardholder.identification.number, cuotas, purchaseCode)
                  .then((res) => {
                      if(!res.result){
                        seterrorMessage(res!.message);
                        toggle();
                        setButtonState({text:"PAGAR", enable:true});
                      } else{
                        navigate(`/pago-finalizado/debitCreditCard/${res.checkoutData.code}`)
                      }
                    })
                    .catch((error) => {
                      seterrorMessage(error!.message);
                      if(error!.details != undefined && error!.details[0] == "reset") {
                        setResetPurchase(true);
  
                      }
                      
                      toggle();
                      setButtonState({text:"PAGAR", enable:true});
                    });
                } else {

                  decidirPayWay(response.bin,response.id, response.cardholder.identification.number, cuotas)
                  .then((res) => {
                      if(!res.result){
                        seterrorMessage(res!.message);
                        toggle();
                        setButtonState({text:"PAGAR", enable:true});
                      } else{
                        navigate(`/pago-finalizado/debitCreditCard/${res.checkoutData.code}`)
                      }
                    })
                    .catch((error) => {
                      seterrorMessage(error!.message);
                      if(error!.details != undefined && error!.details[0] == "reset") {
                        setResetPurchase(true);
  
                      }
                      
                      toggle();
                      setButtonState({text:"PAGAR", enable:true});
                    });
                }

            }
        }
        decidir.createToken(form, sdkResponseHandler);//formulario y callback
    }

    const validateCardNumber = (currentCardNumber: string): void => {
          cardNumberHandle(currentCardNumber);
    };
    return (
      <>
        <Modal isOpen={modal} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>ERROR DE PAGO</ModalHeader>
            <ModalBody className='error-handler'>
                <p className='text-center error-message'>{errorMessage}</p>
                <div className='d-flext justify-content-center p-0 mb-2'>
                    <Row className='text-center'>
                        <Col md={12} className='pb-2 pt-2'>
                            <Button className='cancel-bt ps-5 pe-5' onClick={()=>{ toggle(); }}>CERRAR </Button>
                        </Col>
                    </Row>
                </div>
            </ModalBody>
        </Modal>
        <TarjetaDeCredito
            purchaseCode={purchaseCode}
            formState={formState}
            handleInputChange={handleInputChange}
            submitHandler={submitHandler}
            installments={installments}
            cuotas={cuotas}
            buttonState={buttonState}
            setCuotas={setCuotas}
            validateCardNumber={validateCardNumber}
            issuer={null}
            />
      </>
    );
};

export default connect(
    null,
    {  }
)(DecidirCom);