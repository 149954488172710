import React from 'react';
import { connect } from 'react-redux';
import {Row,Col} from 'reactstrap';
import '../../sass/pasos_compra.scss'
import { eventoSeleccionado } from '../../../types/types';

type propiedades = {
    pasoActualCompra:string,
    eventoSeleccionado: eventoSeleccionado;
}
export type configObjectEventos = {
    eventsReducer: propiedades
}

const pasosCompra = (props:propiedades) => {

    let pasosCompra = [
        { id: "1", title: "Comienza la Compra" },
        { id: "2", title: "Agrega Lugares" },
        { id: "3", title: "Ingresa tus Datos" },
        { id: "4", title: "Realiza el Pago" }
      ];

      if (props.eventoSeleccionado.sessions && props.eventoSeleccionado.sessions.length > 1) {
        pasosCompra = pasosCompra.map((paso, index) => {
          if (index === 0) {
            return { id: paso.id, title: "Selecciona Fecha y Hora" };
          }
          return paso;
        });
      }

    return (
        <Row className='pasoscompra ps-0'>
            {props.eventoSeleccionado.sessions && pasosCompra.map((paso, index)=>{
                return (
                <Col key={index} xs={3} className='p-0'>
                    <div className={paso.id === props.pasoActualCompra ? 'paso paso-actual' : 'paso'}>{paso.id === props.pasoActualCompra ? `Paso ${paso.id}:  ${paso.title}` : `${paso.id}. ${paso.title}`}</div>
                </Col>)
            })}
            {!props.eventoSeleccionado.sessions && <Col className='text-center pt-3 proximamente'>
                PROXIMAMENTE
            </Col>}
        </Row>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        pasoActualCompra: state.eventsReducer.pasoActualCompra,
        eventoSeleccionado: state.eventsReducer.eventoSeleccionado,

    }
}

export default connect(
    mapStateToProps,null
)(pasosCompra);

