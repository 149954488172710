import React, { useState, Dispatch, useEffect } from 'react';
import { Input, Label, InputGroup, InputGroupText, Row, Col, Button } from 'reactstrap';
import { Issuer, installmentData } from '../../../types/types';
import TextField from '../TextField';
import { connect } from 'react-redux';
import Spinner from '../Spinner';

type buttonState = {
    text:string,
    enable:boolean
}

type formState = {
    cardNumber: string,
    expirationMonth: string,
    expirationYear: string,
    securityCode: string,
    HOLDER_NAME: string,
    DOCUMENT: string,
    EMAIL: string,
}

type configObject = {
    eventsReducer: {
        loaderCheckCardNumber: boolean;
    }
}


const TarjetaDeCredito = ({ formState, handleInputChange, submitHandler, installments, cuotas, setCuotas, validateCardNumber, buttonState, issuer, loaderCheckCardNumber, purchaseCode }: { formState: formState, handleInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void, submitHandler: () => void, installments: installmentData[], cuotas: number, setCuotas: Dispatch<number>, validateCardNumber:Dispatch<string>, buttonState:buttonState, issuer: Issuer | null, loaderCheckCardNumber:boolean, purchaseCode: string | null}) => {

    const ErrorIcon = () => {
        return (<>
            <svg role="img" data-testid="sm" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="13px" height="13px"><rect width="12" height="12" rx="6" fill="#F23D4F"></rect><path d="M6.72725 2.90906H5.27271L5.45452 6.90906H6.54543L6.72725 2.90906Z" fill="#FFFFFF"></path><path d="M5.99998 7.63633C6.40164 7.63633 6.72725 7.96195 6.72725 8.36361C6.72725 8.76527 6.40164 9.09088 5.99998 9.09088C5.59832 9.09088 5.27271 8.76527 5.27271 8.36361C5.27271 7.96195 5.59832 7.63633 5.99998 7.63633Z" fill="#FFFFFF"></path></svg>
        </>)
    }

    const [NumeroAnterior, setNumeroAnterior] = useState<string | null>(null);

    const defaultFormErrors = {
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        securityCode: '',
        HOLDER_NAME: '',
        DOCUMENT: '',
        EMAIL: '',
    }
    const [formErrorState, setFormErrorState] = useState(defaultFormErrors);

    const [showError, setShowError] = useState(false);
    useEffect(()=>{
        setNumeroAnterior(formState.cardNumber)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const validateInput = async (name: string, value: string, event: React.ChangeEvent<HTMLInputElement>) => {
        let error = '';

        if (event.target.required && value.trim() === '') {
            if (name === 'cardNumber') {
                error = 'Caracteres de número de tarjeta inválidos';
            } else if (name === 'expirationMonth') {
                error = 'Caracteres de Mes inválidos';
            } else if (name === 'expirationYear') {
                error = 'Caracteres de Año inválidos';
            } else if (name === 'securityCode') {
                error = 'Caracteres de código inválidos';
            } else if (name === 'HOLDER_NAME') {
                error = 'Dato obligatorio';
            } else if (name === 'DOCUMENT') {
                error = 'Dato obligatorio';
            }
        } else if (name === 'expirationMonth') {
            const inputMonthNumber = Number(value);
            if (inputMonthNumber <= 0 || inputMonthNumber > 12 || isNaN(inputMonthNumber)) {
                error = 'Formato inválido';
            }
        } else if (name === 'expirationYear') {
            const inputYearNumber = Number(value);
            let currentYear = new Date().getFullYear();
            currentYear = currentYear % 100;
            if (isNaN(inputYearNumber) || currentYear > (inputYearNumber)) {
                error = 'Formato inválido';
            }
        } else if (name === 'securityCode' && value.length < 3) {
            error = 'Código incompleto';
        } else if (name === 'HOLDER_NAME' && value.length === 1) {
            error = 'Texto muy corto';
        } else if (name === 'cardNumber' && value.length < 8) {
            error = 'Número incompleto';
        } else if (name === 'DOCUMENT' && (value.length < 6)) {
            error = 'Número incompleto';
        } else if (value.length > parseInt(event.target.getAttribute('max') || '')) {
            error = `El campo debe tener como máximo ${event.target.getAttribute('max')} caracteres`;
        } else if (name === 'securityCode' && isNaN(Number(value))) {
            error = 'Formato inválido';
        }

        setFormErrorState(prevState => ({
            ...prevState,
            [name]: error,
        }));

        const parentElement = event.target.parentElement;
        if (parentElement) {
            if (error !== '') {
                parentElement.classList.add('has-error');
            } else {
                parentElement.classList.remove('has-error');
            }
        }

        if (name === 'cardNumber' && error === '' && value !== NumeroAnterior){
            validateCardNumber(value);
            formState.DOCUMENT = '';
            formState.EMAIL = '';
            formState.HOLDER_NAME = '';
            formState.expirationMonth = '';
            formState.expirationYear = '';
            formState.securityCode = '';
            setNumeroAnterior(value);
            setFormErrorState(defaultFormErrors);
                document.querySelectorAll('.fieldset').forEach((elem)=>{
                    elem.classList.remove('has-error')
                })
        }

    };

    return (
        <div className='p-0'>
            <div id="card-container" style={{ "position": "relative" }}>
                <Row>
                    <Col className='mb-2'>
                        <Label for="cardNumber" className='label-form'>N° de Tarjeta</Label>
                        <TextField required={true} max={20} type='number' id='cardNumber' label='' value={formState.cardNumber} errorMsg={formErrorState.cardNumber} handleInputChange={handleInputChange} validate={validateInput} otherProps={{'data-decidir':'card_number'}}/>
                        {loaderCheckCardNumber && <Spinner style={{"top":"8px", "right":"-10px","transform":"scale(0.4)"}}/>}
                    </Col>
                </Row>
                <Row className='codigo-seguridad-vencimiento'>
                    <Col className='mb-2'>
                        <Label for="expirationMonth" className='label-form'>Mes de Vencimiento</Label>
                        <TextField required={true} max={2} type='number' id='expirationMonth' label='MM' value={formState.expirationMonth} errorMsg={formErrorState.expirationMonth} handleInputChange={handleInputChange} validate={validateInput} otherProps={{"data-decidir": "card_expiration_month"}}/>
                    </Col>
                    <Col className='mb-2'>
                        <Label for="expirationYear" className='label-form'>Año de Vencimiento</Label>
                        <TextField required={true} max={2} type='number' id='expirationYear' label='AA' value={formState.expirationYear} errorMsg={formErrorState.expirationYear} handleInputChange={handleInputChange} validate={validateInput} otherProps={{"data-decidir": "card_expiration_year"}}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={7}>
                        <Label for="securityCode" className='label-form'>Código de Seguridad</Label>
                        <TextField required={true} max={4} type='number' id='securityCode' label='' value={formState.securityCode} errorMsg={formErrorState.securityCode} handleInputChange={handleInputChange} validate={validateInput} otherProps={{"data-decidir": "security_code"}}/>
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col>
                        <Label for="HOLDER_NAME" className='label-form'>Nombre del Titular</Label>
                        <TextField required={true} type='text' max={30} id='HOLDER_NAME' label='Tal como figura en la Tarjeta' value={formState.HOLDER_NAME} errorMsg={formErrorState.HOLDER_NAME} handleInputChange={handleInputChange} validate={validateInput} otherProps={{"data-decidir": "card_holder_name"}}/>
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col>
                        <Label for="DOCUMENT" className='label-form'>{purchaseCode ? "DNI / ID del Titular" : "DNI del Titular"}</Label>
                        <TextField required={true} type={purchaseCode ? 'text' : 'number'} max={8} id='DOCUMENT' label={purchaseCode ? 'Nro. DNI / ID (sin puntos)' : 'Nro. DNI (sólo números)'} value={formState.DOCUMENT} errorMsg={formErrorState.DOCUMENT} handleInputChange={handleInputChange} validate={validateInput} otherProps={{"data-decidir": "card_holder_doc_number"}}/>
                    </Col>
                </Row>
                {showError && <div className="mensaje-error"><ErrorIcon />  <p>Completa todos los datos para continuar</p>
                    <Button className='p-0 m-0' color='link' onClick={() => { setShowError(false) }}><svg role="img" width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.35144 5.19496L9.15394 9.99746L4.35144 14.8L5.19997 15.6485L10.0025 10.846L14.7962 15.6397L15.6448 14.7912L10.851 9.99746L15.6448 5.20371L14.7962 4.35518L10.0025 9.14894L5.19997 4.34644L4.35144 5.19496Z" fill="#1a1a1a" fill-opacity="0.55"></path></svg></Button>
                </div>}
                {installments && installments.length > 0 &&
                    <Row className='mb-2'>
                        <Col>
                            <InputGroup className="form-group cuotas">
                                <Label for="CUOTAS" className='label-form'>Nro. de Cuotas</Label>
                                <InputGroupText>
                                    <Input type="select" name="CUOTAS" value={cuotas} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setCuotas(Number(e.target.value)) }} required>
                                        {installments.map((installment, index) => {
                                            return <option value={installment.installment}>{installment.installment + ' Cuota' + ((installment.installment > 1) ? 's' : '') + ' $ ' + installment.amount + ' ($ '+installment.totalAmount+')'}</option>;
                                        })}
                                    </Input>
                                </InputGroupText>
                                {formErrorState.DOCUMENT && <span className="error"><ErrorIcon /> {formErrorState.DOCUMENT}</span>}
                            </InputGroup>
                        </Col>
                    </Row>}
                <Row className='mb-2'>
                    <Col className='text-center flex-column pagar-btn'>
                        <Button disabled={!buttonState.enable} className='btn btn-secondary' onClick={submitHandler}>
                            <span className='ms-1'> {buttonState.text}</span>
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

const mapStateToProps = (state: configObject) => {
    return {
        loaderCheckCardNumber: state.eventsReducer.loaderCheckCardNumber,
    }
}


export default connect(
    mapStateToProps,
    {}
)(TarjetaDeCredito);
