import React, { useState } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { datosCompra, Ticket } from '../../../types/types';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { purchaseDeleteDetail } from '../../../api/purchase';
import { fetchDetalleCompra } from '../../../reducer/events-reducer';
import CodigoDescuento from './CodigoDescuento';
import {
    Row,
    Col,
} from 'reactstrap';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getLangValue } from '../../../api/config';

type configObjectEventos = {
    eventsReducer: {
        detalleCompra: datosCompra;
        pasoActualCompra: string;
    }
}

const DetalleCompra = ({ detalleCompra, fetchDetalleCompra, pasoTres, pasoActualCompra, viewComissionText }: { detalleCompra: datosCompra, fetchDetalleCompra:()=> any, pasoTres?:boolean, pasoActualCompra:string, viewComissionText?:boolean }) => {

    const [open, setOpen] = useState(false);
    const [ticket, setTicket] = useState<Ticket | null>(null);
    const lang = getLangValue('lang');

    const handleClickOpen = (record:Ticket) => {
        setTicket(record);
        setOpen(true);
    };

    const handleClose = () => {
        setTicket(null);
        setOpen(false);
    };


    const handleButtonClick = async() =>{
        if (ticket) {
            const res = await purchaseDeleteDetail(ticket.detailCode);
            if (res.result === true) {
                await fetchDetalleCompra();
                let info = res.payload
                const eventData = {
                    codigo: info.code, 
                    estatus: info.status,
                    tipo: info.type
                };
                const customEvent = new CustomEvent('on_venta_detalle_eliminada', {detail: eventData});
                document.dispatchEvent(customEvent);
                
            }
            handleClose();
        }
    }
    
    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
        },
        {
            title: 'Sector',
            dataIndex: 'sector',
            key: 'sector',
        },
        {
            title: 'Lugar',
            dataIndex: 'place',
            key: 'place',
        },
        {
            title: 'Precio',
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: ' ',
            dataIndex: 'delete',
            key: 'delete',
            className: !pasoTres ? 'last-column' : 'last-column-paso-3',
            render: (text:string, record:Ticket) => (
                <>
                    {text}
                    <button onClick={() => handleClickOpen(record)}><ClearOutlinedIcon /></button>
                </>
            ),
        },
    ];

    const data = detalleCompra.tickets.map((ticket, index) => ({
        ...ticket,
        key: index.toString(),
    }));
    //}

    return (
        <>
            <h4 className='subtitle'>Detalle de tu Compra</h4>
            {detalleCompra && <>
                <Dialog 
                    open={open} 
                    onClose={handleClose} 
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title" className='text-center'>
                            {"Eliminar Item"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Realmente deseas eliminar este Item de la Compra?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{justifyContent: 'center'}}>
                            <Button style={{"color": "#380AB4"}} onClick={handleClose}><b>Cancelar</b></Button>
                            <Button onClick={handleButtonClick} autoFocus>
                                <b>Aceptar</b>
                            </Button>
                        </DialogActions>
                </Dialog>
                <Table className='detalle-compra-table' columns={columns} dataSource={data} pagination={false} />
                {!pasoTres && <>
                    <Col>
                        <Row className='total-detalle-compra pt-2 pb-2'>
                            <Col xs={3} className='ps-3'> {detalleCompra.ticketsQuantity > 1 ? 'ITEMS' : 'ITEM'} </Col>
                            <Col xs={3} className='ps-4'> {detalleCompra.ticketsQuantity} </Col>
                            <Col xs={3}>TOTAL</Col>
                            <Col xs={3} className='ps-0'>{detalleCompra.totalTickets}</Col>
                        </Row>
                        <Row>
                            <hr className='detalle-compra-hr'/>
                        </Row>
                    </Col>
                    {detalleCompra.amountDiscount < 0 && <>
                        <Row className='total-detalle-compra pt-2 pb-2'>
                            <Col xs={9} className='ps-3'> {detalleCompra.discountName} </Col>
                            <Col xs={3}>{detalleCompra.totalDiscount}</Col>
                        </Row>
                        <Row>
                            <hr className='detalle-compra-hr'/>
                        </Row>
                    </>}
                </>}
                {pasoTres  && <>
                    <Row className='total-detalle-compra pt-2 pb-2'>
                        <Col xs={8} className='ps-3'> SUBTOTAL (por cta. y orden) </Col>
                        <Col xs={4} className='text-end'> {detalleCompra.totalTickets} </Col>
                    </Row>
                    <Row>
                        <hr className='detalle-compra-hr'/>
                    </Row>
                    <Row className='total-detalle-compra pt-2 pb-2'>
                        <Col xs={8} className='ps-3'> Servicio Venta Internet {lang === 'es_AR' && (pasoActualCompra !== "4" || viewComissionText) && (detalleCompra.commission !== "$ 0,00") && <><br/><small>(este cargo puede variar según el medio de pago)</small></>}</Col>
                        <Col xs={4} className='text-end'> {detalleCompra.commission} </Col>
                    </Row>
                    <Row>
                        <hr className='detalle-compra-hr'/>
                    </Row>
                    {detalleCompra.amountDiscount < 0 && <>
                        <Row className='total-detalle-compra pt-2 pb-2'>
                            <Col xs={6} className='ps-3'> {detalleCompra.discountName} </Col>
                            <Col xs={6} className='text-end'>{detalleCompra.totalDiscount}</Col>
                        </Row>
                    </>}
                    <Row className='total-detalle-compra pt-2 pb-2'>
                        <Col xs={6} className='ps-3'> TOTAL </Col>
                        <Col xs={6} className='text-end'> {detalleCompra.totalToPaid} </Col>
                    </Row>
                    <Row>
                        <hr className='detalle-compra-hr'/>
                    </Row>
                </>}
                {detalleCompra.hasPromoElective && detalleCompra.ticketsQuantity > 0 && detalleCompra.amountDiscount === 0 && pasoActualCompra === "2" && <CodigoDescuento />}
                </>
            }
        </>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        detalleCompra: state.eventsReducer.detalleCompra,
        pasoActualCompra: state.eventsReducer.pasoActualCompra,
    }
}

export default connect(
    mapStateToProps,
    {fetchDetalleCompra}
)(DetalleCompra);
