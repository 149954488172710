import React from 'react';
import { evento } from '../../../types/types';
import { Row, Col, Button } from 'reactstrap';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import BoyOutlinedIcon from '@mui/icons-material/BoyOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Table } from 'antd';

import '../../sass/events.scss';

interface propiedades {
  evento: evento;
  handleEvent: (itemTypeGroup: string) => void;
}

const EventoTexto = (props: propiedades) => {
  const { evento, handleEvent } = props;

  const contentTemplate = (label: string, value: string | null, className?:string) => {
    if (value) {
      const lines = value.split('\n');
      return (
        <>
          {lines.map((line, index) => (
            <React.Fragment key={index}>
              {className ? <span className={className}>{line}</span> : line}
              {index !== lines.length - 1 && <br />}
            </React.Fragment>
          ))}
        </>
      );
    } else {
      return null;
    }
  };

  const placeLocationTemplate = (place: string, location: string) => {
    return (
      <>
        <p className='p-0 m-0 text-data'><strong>{place}</strong></p>
        <p className='p-0 m-0 text-muted text-data'>{location}</p>
      </>
    )
  }

  const data: { key: string; icon?: JSX.Element | null; content?: JSX.Element | null }[] = [
    {
      key: "1",
      icon: evento.location ? <PinDropOutlinedIcon /> : null,
      content: placeLocationTemplate(evento.place!, evento.location)
    },
    evento.dates ? {
      key: "2",
      icon: <CalendarTodayOutlinedIcon />,
      content: contentTemplate('Fecha', evento.dates, 'fw-bold text-data')
    } : {
      key: "2",
      icon: null,
      content: null
    },
    {
      key: "3",
      icon: <QueryBuilderOutlinedIcon />,
      content: contentTemplate('Horario / Duración', evento.hours, 'fw-bold text-data')
    },
    {
      key: "4",
      icon: evento.publicPresentationPlace ? <BoyOutlinedIcon  className='custom-icon'/> : null,
      content: contentTemplate('Presencialidad', evento.publicPresentationPlace,'text-capitalize text-data')
    }
  ].filter(item => item.icon !== null && item.content !== null);

  return (
    <Row className='event-text mt-2 mb-2'>
      <Col className='text-start border p-0 m-2'>
        <div className='header pb-2 ps-3 pe-2'>
          <div className='content-type d-inline-block ps-2 pe-2'>{evento.contentType}</div>
          <div className='title' dangerouslySetInnerHTML={{ __html: evento.title }}></div>
        </div>
        <Table
          dataSource={data}
          pagination={false}
          className="table table-striped"
          rowClassName={() => 'table-row'}
          showHeader={false}
          columns={[
            {
              dataIndex: 'icon',
              key: 'icon',
              align: 'center',
              className: 'table-column'
            },
            {
              dataIndex: 'content',
              key: 'content',
              className: 'table-column-content',
              render: (content: JSX.Element) => <>{content}</>
            }
          ]}
        />
          <Col className='d-flex justify-content-center buttons-wrapper'>
            <Button className='ms-1 me-1 mb-3' onClick={() => { handleEvent(evento.eventHash) }}>
              INFORMACIÓN + COMPRAR ENTRADAS <span className='comprar-icon ms-1'><ArrowForwardIosIcon /></span>
            </Button>
          </Col>
      </Col>
    </Row>
  );
};

export default EventoTexto;