import { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GenericObject, PurchaseData, datosCompra, sectorList } from '../../../types/types';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Input, ModalFooter } from 'reactstrap';
import { fetchDetalleCompra } from '../../../reducer/events-reducer';
import { getPrices, purchaseSell } from '../../../api/purchase';
import { Table } from 'antd';
import '../../sass/evento_seleccionado.scss'
import DetalleCompra from './DetalleCompra';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { mostrarError } from '../../../reducer/config-reducer';
export type configObjectEventos = {
    eventsReducer: {
        datosCompraPasoDos: PurchaseData;
        detalleCompra: datosCompra;
    };
};

const CompraEventoPlural = ({
    datosCompraPasoDos,
    fetchDetalleCompra,
    detalleCompra,
    mostrarError
}: {
    datosCompraPasoDos: PurchaseData;
    fetchDetalleCompra: () => any;
    detalleCompra: datosCompra;
    mostrarError:Dispatch<GenericObject | null>;
}) => {
    const [modal, setModal] = useState<boolean>(false);
    const [selectedListItem, setSelectedListItem] = useState<sectorList | null>(null);
    const [selectedPriceIndex, setSelectedPriceIndex] = useState<number | null>(null);
    const [disableBt, setdisableBt] = useState(false);
    const [errorMessage, seterrorMessage] = useState();
    const [errorModal, setErrorModal] = useState<boolean>(false);

    const columns = [
        {
            title: '',
            dataIndex: 'radio',
            key: 'radio',
            render: (_: any, record: any) => (
                <Input type="radio" checked={record.key === selectedPriceIndex} onChange={() => handlePriceSelection(record.key)} />
            ),
        },
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
    ];

    const toggle = () => {
        setModal(!modal);
    };

    const toggleErrorModal = () => {
        setErrorModal(!errorModal);
    }

    const handlePriceSelection = (index: number) => {
        setSelectedPriceIndex(index);
    };

    const handleSector = async (sector: sectorList) => {
        const response = await getPrices(sector.code);

        if(response.result === true){
            sector.prices = response.prices;
            toggle();
            setSelectedListItem(sector);
        }
        
    }

    const handleAceptarButton = async() => {
        setdisableBt(true)
        if (selectedPriceIndex !== null) {
            const res = await purchaseSell(selectedListItem!.code, selectedPriceIndex);
            if (res.result === true) {
                await fetchDetalleCompra();
                setSelectedPriceIndex(null);
                setdisableBt(false);
                toggle();
            } else {
                let message = res.message;
                if (message.includes('NO_ACEPTA_MAS_VENTAS')) {
                    message = "No hay más Lugares Disponibles";
                }

                seterrorMessage(message);
                toggle();
                toggleErrorModal();
            }
            setdisableBt(false)
        }
    }

    useEffect(() => {
        fetchDetalleCompra();


    }, [fetchDetalleCompra]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dataSource = selectedListItem
        ? selectedListItem.prices.map((precio, index) => ({
            key: index,
            radio: <Input type="radio" name='radio' />,
            label: precio.label,
            price: precio.price,
        }))
        : [];

        useEffect(()=>{
            if (dataSource.length === 1) {
                handlePriceSelection(0);
              }
        },[dataSource])
    return (
        <>
            <Modal isOpen={errorModal} toggle={toggleErrorModal} centered={true}>
                <ModalHeader toggle={toggleErrorModal}>AVISO</ModalHeader>
                <ModalBody className='error-handler'>
                    <p className='text-center error-message'>{errorMessage}</p>
                    <div className='d-flext justify-content-center p-0 mb-2'>
                        <Row className='text-center'>
                            <Col md={12} className='pb-2 pt-2'>
                                <Button className='cancel-bt ps-5 pe-5' onClick={()=>{ toggleErrorModal(); }}>CERRAR </Button>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modal} toggle={toggle} centered={true} className='modal-compra-evento-plural' backdrop='static'>
                <ModalHeader toggle={toggle}>ESTAS AGREGANDO UN<br /> ITEM A TU COMPRA</ModalHeader>
                {selectedListItem && (
                    <>
                        <ModalBody className='pb-0'>
                                <Col>
                                    <h5 className="text-center">{selectedListItem.name}</h5>
                                </Col>
                                <Col className='mt-3 mb-3'>
                                    <p className='text-center'>Selecciona Tipo de Precio:</p>
                                    <Table columns={columns} dataSource={dataSource} pagination={false} showHeader={false} />
                                </Col>
                        </ModalBody>
                        <ModalFooter className='d-flext justify-content-center p-0 mb-2'>
                            {/*<Button className='cancel-bt' onClick={()=>{ toggle(); setSelectedPriceIndex(null) }}>CANCELAR <CloseIcon /></Button>*/}
                            <Button disabled={disableBt} onClick={handleAceptarButton}>ACEPTAR <ArrowForwardIosIcon /></Button>
                        </ModalFooter>
                    </>
                )}
            </Modal>
            <div>
                <p className='compra-paso2-info-text'>Toca el Sector y repite tantas veces como lugares a agregar</p>
                <div className='button-wrapper-tipo-plural'>{datosCompraPasoDos.sectorList.map((sector, index) => {
                    if (sector.enable) {
                        return (
                            <Button className='compra-plural-bt mb-2' key={index} onClick={() => {
                                handleSector(sector)
                            }}>
                                {sector.name}
                            </Button>
                        );
                    }
                })}</div>

                <Row className="mt-4 mb-4">
                    <Col>

                        {detalleCompra && <DetalleCompra />}
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        datosCompraPasoDos: state.eventsReducer.datosCompraPasoDos,
        detalleCompra: state.eventsReducer.detalleCompra,
    };
};

export default connect(
    mapStateToProps,
    { fetchDetalleCompra, mostrarError }
)(CompraEventoPlural);