import { useState } from 'react';
import { connect } from 'react-redux';
import { fetchDetalleCompra } from '../../../reducer/events-reducer';
import { datosCompra } from '../../../types/types';
import {getPromotionCode} from '../../../api/purchase'
import {
    Col,
    Input,
    Button,
    Label
} from 'reactstrap';
type configObjectEventos = {
    eventsReducer: {
        detalleCompra: datosCompra;
    }
}

const CodigoDescuento = ({ detalleCompra, fetchDetalleCompra }: { detalleCompra: datosCompra, fetchDetalleCompra: () => any }) => {
    const [codigoDescuento, setCodigoDescuento] = useState<string>('')
    const [codigoErroneo, setCodigoErroneo] = useState<boolean>(false)
    const [messageCodigoErroneo, setMessageCodigoErroneo] = useState<string>('')

    const submitHandler = async() => {
        const res = await getPromotionCode(codigoDescuento)
        if(res.result === true){
            fetchDetalleCompra();
        }else{
            setCodigoErroneo(true)
            setMessageCodigoErroneo(res.message);
        }
    }

    return (
        <Col className='text-start codigo-descuento'>
            <Label className='mt-3'>¿Tienes Código de Descuento?</Label>
            <Input type='text' placeholder='ingresar código'
                value={codigoDescuento}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => { setCodigoDescuento(evt.target.value) }}
                onFocus={()=>{
                    if(codigoErroneo){
                        setCodigoDescuento('');
                        setCodigoErroneo(false);
                        setMessageCodigoErroneo('');
                    }
                }}
            />
            {codigoErroneo && <div className='d-flex justify-content-center mt-2'><span className='badge bg-danger p-2'>{messageCodigoErroneo}</span></div> }
            <div className='d-flex justify-content-center mt-2'>
                <Button onClick={submitHandler}>APLICAR DESCUENTO</Button>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        detalleCompra: state.eventsReducer.detalleCompra
    }
}

export default connect(
    mapStateToProps,
    { fetchDetalleCompra }
)(CodigoDescuento);
