import {useState} from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import {directPay} from '../../../api/purchase';
/* import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'; */

const TipoPagoDirectPaid = () => {
    const [dni, setDni] = useState('');
    
    /* initMercadoPago('APP_USR-12497abe-d74a-4412-b3ac-3026dd637c69');
    const customization = {
        visual: {
          texts: {
            action: 'Dinero en cta. en Mercado Pago',
            valueProp: 'security_details'
          }
        }
       }; */
    const submitHandler = async() => {
        const res = await directPay(dni, "MOBILE");
        if(res.id){
            window.location.href = `https://www.mercadopago.com.ar/checkout/v1/redirect?pref_id=${res.id}`;
        }
    }

    return (
        <div>
            
                <>
                <Row>
                    <Col>
                        <h4 className='subtitle'>Ingresa tu DNI</h4>
                    </Col>
                </Row>
                <Row className='form-data'>
                    <Col>
                        <Input type="number" max={16} placeholder='Nro. de DNI sin puntos y sin espacios' value={dni} onChange={(e)=>{setDni(e.target.value)}}/>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-center flex-column pagar-btn'>
                        <p><strong>IMPORTANTE:</strong> con el botón que está más abajo te vamos a redireccionar a Mercado Pago. Luego de pagar, busca en la pantalla que te muestra Mercado Pago el botón "VOLVER AL SITIO", que aparece bien al final de dicha pantalla y hazle click. Si no haces esto NO se generaran las entradas.</p>
                        <Button className='w-100 mt-3 mb-4' onClick={submitHandler}>Dinero en Cta. Mercado Pago</Button>
                    </Col>
                </Row>
                </>
            
            {/*datosRecibidos &&
                <Row>
                    <Col className='text-center flex-column pagar-btn'>
                        <p><strong>IMPORTANTE:</strong> con el botón que está más abajo te vamos a redireccionar a Mercado Pago. Luego de pagar, busca en la pantalla que te muestra Mercado Pago el botón "VOLVER AL SITIO", que aparece bien al final de dicha pantalla y hazle click. Si no haces esto NO se generaran las entradas.</p>
                        
                        <Link to={redirectLink} ><Button className='w-100 mb-2'>Dinero en cta. en Mercado Pago</Button></Link>
                    </Col>
                </Row>
            */}
        </div>
    );
};

export default TipoPagoDirectPaid;