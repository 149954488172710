import React, {Dispatch} from 'react';
import { useState } from 'react';
import { session } from '../../../types/types';
import {
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';

const SeleccionarFechaYHora = ({ sesiones, status, horaSeleccionada, setHoraSeleccionada }: { sesiones: session[], status: string, horaSeleccionada:string, setHoraSeleccionada:Dispatch<string>  }) => {
    const [indiceSeleccionado, setIndiceSeleccionado] = useState(0);
    const [dropdownOpenFecha, setDropdownOpenFecha] = useState(false);
    const [opcionSeleccionada, setOpcionSeleccionada] = useState('Selecciona Fecha')
    const [opcionSeleccionadaHora, setOpcionSeleccionadaHora] = useState('Selecciona Horario')
    const [dropdownOpenHora, setDropdownOpenHora] = useState(false);

    const handleDropDownFecha = () => {
        setDropdownOpenFecha((prevState) => !prevState);
    }

    const handleDropDownHora = () => {
        setDropdownOpenHora((prevState) => !prevState);
    }
    const handleFecha = (title: string, index: number) => {
        setOpcionSeleccionada(title);
        setIndiceSeleccionado(index);
        setOpcionSeleccionadaHora('Seleccione Hora')
        handleDropDownFecha()
    }
    const handleHora = (title: string,) => {
        setOpcionSeleccionadaHora(title);
        handleDropDownHora()
    }

    return (
        <Row>
            <Col>
                <Dropdown isOpen={dropdownOpenFecha} toggle={handleDropDownFecha} direction='down'>
                    <DropdownToggle caret tag='div' className='select'>
                        <span>{opcionSeleccionada}</span>
                    </DropdownToggle>
                    <DropdownMenu tag={'ul'} className='options'>
                        {sesiones.map((sesion, index) => {
                            return <li key={index} onClick={() => { handleFecha(sesion.title, index) }}>{sesion.title} {status === "0" ? '(Agotadas)' : null}</li>;
                        })}
                    </DropdownMenu>
                </Dropdown>

                <Dropdown isOpen={dropdownOpenHora} toggle={handleDropDownHora} direction='down'>
                    <DropdownToggle caret tag='div' className='select'>
                        <span>{opcionSeleccionadaHora}</span>
                    </DropdownToggle>
                    <DropdownMenu tag={'ul'} className='options'>
                        {opcionSeleccionada !== 'Selecciona Fecha' && sesiones[indiceSeleccionado].hours.map((hora, index) => {
                            return <li className={hora.status === "0" ? 'disable-date' : ""} key={index} onClick={() => { handleHora(hora.hour); setHoraSeleccionada(hora.code) }}>{hora.hour}</li>;
                        })}
                    </DropdownMenu>
                </Dropdown>

            </Col>
        </Row>
    );
};
export default SeleccionarFechaYHora;