import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
type propiedades = {
    paymentMethod: string,
    additionalCode: string
}

const Cash = (props: propiedades) => {

    const { paymentMethod, additionalCode } = props;
    const metodoDePago = paymentMethod === 'pagofacil' ? 'Pago Fácil' : paymentMethod === 'rapipago' ? 'Rapipago' : '';

    return (
        <>
            <h2 className='title-pay-succes text_3 mt-4'>¡Generamos tu Código de Pago en Efectivo!</h2>
            <div className='text-start'>
                <p className='pay-success-description'>Elegiste ir a pagar a <strong>{metodoDePago}</strong>. Presentá este código de Mercado Pago por ventanilla para que te cobren.</p>
                <h4 className='text-center'>
                    <strong>Código de Mercado Pago<br />
                    {additionalCode}</strong> 
                </h4>
                <p className='pay-success-description'>
                   <strong>IMPORTANTE:</strong> debes realizar el pago antes de que transcurran las 24hs. <br />
                   No te preocupes que tus lugares, están reservados hasta que <strong>Mercado Pago</strong> nos confirme tu pago. <br />
                   Si se vence el plazo de 24hs. los lugares se liberarán y deberás realizar de nuevo tu compra.
                </p>
                <div className='d-flex justify-content-center pb-5'><Link to='/'><Button> VOLVER AL INICIO</Button></Link></div>
            </div>

        </>
    );
};

export default Cash;