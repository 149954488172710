import React, { useState, useEffect,Dispatch } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { checkCardNumber, sendCardNumber } from '../../../api/purchase';
import MercadoPago from './MercadoPago';
import DecidirCom from './DecidirCom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TextField from '../TextField';
import { Issuer, installmentData } from '../../../types/types';
import { getAyuda } from '../../../api/helpText';
import { connect } from 'react-redux';
import { cambiarLoaderCheckCardNumber, fetchDetalleCompra } from '../../../reducer/events-reducer'
import { parseJWt } from '../../../utilities/utilities';
import Spinner from '../Spinner';
import { sendCardNumberFinishPurchase } from '../../../api/sellerPurchase';
import { getLangValue } from '../../../api/config';
import MercadoPagoDeviceId from '../../../utilities/MercadopagoDeviceId';


const TipoPagoTarjeta = ({cambiarLoaderCheckCardNumber, fetchDetalleCompra, handleViewComissionText, presetedCardNumber, purchaseCode}:{cambiarLoaderCheckCardNumber:Dispatch<boolean>, fetchDetalleCompra:Dispatch<void>, handleViewComissionText:Dispatch<void>, presetedCardNumber: string | null, purchaseCode: string | null}) => {
    const [modalMessage, setModalMessage] = useState<string>('');
    const [modal, setModal] = useState<boolean>(false);
    const [cardNumber, setCardNumber] = useState<string>('');
    const [issuer, setIssuer] = useState<Issuer | null>(null);
    const [installments, setInstallments] = useState<installmentData[]>([]);
    const [error, setError] = useState<string>('');
    const [paymentGateway, setPaymentGateway] = useState<'mercadopago' | 'decidircom' | null>(null)
    const [errorMessage, seterrorMessage] = useState<string>('');
    const [modalError, setModalError] = useState<boolean>(false);
    const [payWayPublicKey, setPayWayPublicKey] = useState<string | null>(null);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [langValue] = useState<string>(getLangValue("lang"));

    const cardNumberHandle = async () => {
       getCardNumberData();
    }

    const getCardNumberData = async (otherCardNumber?:string) => {
        try {
            cambiarLoaderCheckCardNumber(true)
            let res = null;
            if(!purchaseCode) {
                res = await sendCardNumber(otherCardNumber ? otherCardNumber : cardNumber)
            } else {
                let deviceId = document.querySelector("#deviceId") as HTMLInputElement;
                res = await sendCardNumberFinishPurchase(otherCardNumber ? otherCardNumber : cardNumber, purchaseCode, deviceId.value)
            }

            if (res.result) {
                otherCardNumber && setCardNumber(otherCardNumber);
                setPaymentGateway(res.paymentGateway)
                setInstallments(res.installments);
                setIssuer(res.issuer);
                setPayWayPublicKey(res.publicKey);
                cambiarLoaderCheckCardNumber(false)
                handleViewComissionText();
                if(!purchaseCode) {
                    fetchDetalleCompra();
                }
            } else {
                cambiarLoaderCheckCardNumber(false)
                seterrorMessage(res.message);
                toggleError();
            }
            
        } catch (error: any) {
            seterrorMessage('Número no válido');
            toggleError();
            cambiarLoaderCheckCardNumber(false)
        }finally {
            setLoadingData(false);
         }
    }

    const checkCard = async () => {
        try {
           cambiarLoaderCheckCardNumber(true)
           setLoadingData(true);
           const res = await checkCardNumber()
           if (res.result) {
                setCardNumber(res.cardNumber)
                setPaymentGateway(res.paymentGateway)
                setInstallments(res.installments);
                setIssuer(res.issuer);
                setPayWayPublicKey(res.publicKey);
                cambiarLoaderCheckCardNumber(false)
                handleViewComissionText();
                fetchDetalleCompra();
           } 
        } catch (error: any) {
            seterrorMessage('Número no válido');
            toggleError();
            cambiarLoaderCheckCardNumber(false)
        } finally {
            setLoadingData(false);
        }
    }

    const modalText = async () => {
        try {
            const res = await getAyuda("mediosdepago")
            setModalMessage(res.text);
        } catch (error: any) {
            setError('número no válido')
        }
    }

    const toggleError = () => {
        setModalError(!modalError);
        if(!modalError){
            setPaymentGateway(null)
            setCardNumber('')
        }
    }

    const toggle = () => {
        setModal(!modal);

    };

    useEffect(() => {
        modalText();
        if (error !== '' && document.querySelector("#numeroTarjeta")) {
            document.querySelector("#numeroTarjeta")!.classList.add('has-error');
        } else {
            document.querySelector("#numeroTarjeta")!.classList.remove('has-error');
        }
    }, [error])

    useEffect( () => {
        if(presetedCardNumber){
            setLoadingData(true);
            getCardNumberData(presetedCardNumber);
        } else {
            const token = localStorage.getItem('token');
            if (token) {
                const payload = parseJWt(token);
                const hasCreditCard = payload!.has_c;
                if(hasCreditCard) {
                    checkCard();
                }
            }
        }
        
        if(purchaseCode && langValue === 'es_AR'){
            const mercadoPagoDeviceId = new MercadoPagoDeviceId();
        }

    }, [presetedCardNumber, purchaseCode])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setCardNumber(event.target.value)
    }
    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered={true} className='ayuda'>
                <ModalHeader toggle={toggle}>MEDIOS DE PAGOS RECIBIDOS</ModalHeader>
                <>
                    <ModalBody className=''>
                        <div dangerouslySetInnerHTML={{__html: modalMessage}}></div>
                    </ModalBody>
                </>
            </Modal>
            
            <Modal isOpen={modalError} toggle={toggleError} centered={true}>
                <ModalHeader toggle={toggleError}>ERROR</ModalHeader>
                <ModalBody className='error-handler'>
                    <p className='text-center error-message'>{errorMessage}</p>
                    <div className='d-flext justify-content-center p-0 mb-2'>
                        <Row className='text-center'>
                            <Col md={12} className='pb-2 pt-2'>
                                <Button className='cancel-bt ps-5 pe-5' onClick={()=>{ toggleError(); }}>CERRAR </Button>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
            {loadingData && 
            <div style={{'opacity': "0.2", "height": "140px"}}>
                <Spinner className='text-center' style={{ "top": "50%", "left": "50%", "zIndex":"9000"}} />
            </div>}
            {!loadingData && !paymentGateway && <>
                <Row>
                    <Col className='mb-3 text-center'>
                        <Button className='medios-de-pago' onClick={toggle}>VER MEDIOS DE PAGO</Button>
                    </Col>
                </Row>
                <div className='form-datos'>
                    {purchaseCode && <input type="hidden" id="deviceId" name='deviceId'/>}
                    <Label for="numeroTarjeta" className='subtitle mb-0'>Ingrese Nro. de Tarjeta</Label>
                    <TextField required={true} type='number' id='numeroTarjeta' label='Sólo números, sin espacios ni puntos' value={cardNumber} errorMsg={error} handleInputChange={handleInputChange} focusEvent={() => { setError(''); setCardNumber('') }} />
                </div>
                <Row className='mt-3 mb-4 steps-buttons'>
                    <Col>
                        <Button className='ps-3 pe-3 w-100' onClick={cardNumberHandle}> <strong>CONTINUAR  <ArrowForwardIosIcon /></strong></Button>
                    </Col>
                </Row></>}
            {paymentGateway &&
            <Row>
                <Col>
                    <h4 className='subtitle'>Completa los datos de la Tarjeta</h4>
                </Col>
            </Row>}
            {paymentGateway === 'mercadopago' && <MercadoPago cardNumber={cardNumber} installments={installments} issuer={issuer} cardNumberHandle={getCardNumberData} purchaseCode={purchaseCode} />}
            {paymentGateway === 'decidircom' && <DecidirCom cardNumber={cardNumber} installments={installments} cardNumberHandle={getCardNumberData} publicKey={payWayPublicKey} purchaseCode={purchaseCode} />}
        </>
    );
};

export default connect(
    null,
    {cambiarLoaderCheckCardNumber, fetchDetalleCompra}
)(TipoPagoTarjeta);
