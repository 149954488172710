import React, {useEffect, useState, Dispatch} from 'react';
import { Modal,  ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { fetchTextoAyuda } from '../../reducer/config-reducer'
import { connect } from 'react-redux';
import "../sass/modalayuda.scss"
import { getTelefonoAyuda } from '../../api/helpText';

import { isMobile } from 'react-device-detect';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { FormattedMessage } from 'react-intl';

interface propiedades {
    textoAyuda:string,
    fetchTextoAyuda: Dispatch<string>,
    tituloModalAyuda:string
}

interface configAyuda {
    configReducer: {
        textoAyuda:'',
        tituloModalAyuda:string
      }
  }
interface telefonoAyuda {
    id:string,
    type:string,
    text:string
}
const AyudaModal = (props:propiedades) => {
    const [modal, setModal] = useState(true);
    const [telefonoAyuda, setTelefonoAyuda] = useState<telefonoAyuda>({id:'', type: '',text: ''});
    const {fetchTextoAyuda} = props;
    const toggle = () => {
        setModal(!modal);
    }
    useEffect(()=>{
        if(!modal){
            fetchTextoAyuda('')
        } else {
            (async () => {
                const res = await getTelefonoAyuda();
                setTelefonoAyuda(res);
            })();
        }
    },[modal, fetchTextoAyuda])

    return (
        <Modal  className='ayuda' isOpen={modal} toggle={toggle} centered={true}>
          <ModalHeader toggle={toggle}><FormattedMessage id={props.tituloModalAyuda} /></ModalHeader>
          <ModalBody>
            <div dangerouslySetInnerHTML={{__html: props.textoAyuda}}></div>
          </ModalBody>
          <ModalFooter>
            <div className='ayuda-online bottom-0 w-100 text-center'>
                <a target="_blank" href={isMobile ? `https://wa.me/${telefonoAyuda.text}` : `https://web.whatsapp.com/send?phone=${telefonoAyuda.text}`} rel="noreferrer">
                    <Button>Ayuda online <WhatsAppIcon className='ms-1'/></Button>
                </a>
            </div>
          </ModalFooter>
        </Modal>
    );
};

const mapStateToProps = (state: configAyuda) => {
    return {
        textoAyuda: state.configReducer.textoAyuda,
        tituloModalAyuda: state.configReducer.tituloModalAyuda
    }
}
export default connect(
    mapStateToProps,
    { fetchTextoAyuda }
)(AyudaModal);
