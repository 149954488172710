import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import '../sass/cookie_policy.scss';
import {getLangValue} from '../../api/config';

const CookiePolicy = () => {

    const [accept, setAccept] = useState(false);
    const [language, setLanguage] = useState<null | string>(null);
    const [showOverlay, setShowOverlay] = useState(!accept);
    const [modal, setModal] = useState<boolean>(false);

    const setCookie = (cname:string, cvalue:number, exdays:number) => {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const getCookie = (cname:string) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    const toggle = () => {
        setModal(!modal);
    };

    useEffect(()=>{
        const lang = getLangValue('lang');
        setLanguage(lang);
    },[])

    const acceptCookiesEventHandler = () =>{
        setAccept(true);
        setShowOverlay(false);
        setTimeout(function(){setCookie('user_cookie_consent', 1, 30)}, 1000);
    }

    const declineCookiesEventHandler = () => {
        setModal(!modal);
    }

    const divClassName = `cookie-policy pt-3 pb-2 w-100 text-white fixed-bottom animate__animated ${accept ? 'animate__slideOutDown' : 'animate__slideInUp animate__delay-2s'}`;

    const overlayStyle:React.CSSProperties = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.1)', // Fondo semi-transparente
        display: showOverlay ? 'block' : 'none',
        zIndex: 1048, // Asegura que esté por encima del resto del contenido
    };

    return (
        <>{language === 'es_ES'  && getCookie("user_cookie_consent") === '' && 
        <div style={overlayStyle}>
            <div className={divClassName}>
                <Modal isOpen={modal} toggle={toggle} centered={true} className='ayuda'>
                    <ModalHeader toggle={toggle}>AVISO DE COOKIES</ModalHeader>
                    <>
                        <ModalBody className='cookie-modal-body'>
                            <p>Lo sentimos, pero al no Aceptar nuestras cookies básicas para poder ejecutar tu compra, nos es imposible brindarte nuestro servicio para que accedas a tus entradas. Por favor cierra esta ventana y Acepta las cookies.</p>
                            <div className='d-flext justify-content-center p-0 mb-2'>
                                <Row className='text-center'>
                                    <Col md={12} className='pb-2 pt-2'>
                                        <Button className='cancel-bt ps-5 pe-5' onClick={()=>{ toggle(); }}>CERRAR </Button>
                                    </Col>
                                </Row>
                            </div>
                        </ModalBody>
                    </>
                </Modal>
                <Container>
                    <Row>
                        <Col className='text-center'><p>Para poder operar en nuestro sitio, debes dar tu consentimiento al uso de nuestras cookies. Sólo utilizamos cookies básicas necesarias para que puedas generar tu compra en Entradaweb. No utilizamos cookies de rastreo o similares.</p></Col>
                    </Row>
                    <Row>
                        <Col className='text-center mt-2'>
                            <Button className='m-2 mb-2' onClick={acceptCookiesEventHandler}>Aceptar las Cookies</Button>
                            <Button className='m-2 mb-2' onClick={declineCookiesEventHandler}>Rechazar</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>}</>
    );
};

export default CookiePolicy;