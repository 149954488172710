import React from 'react';
import EventoTexto from './EventoTexto';
import EventoImagen from './EventoImagen';
import { evento } from '../../../types/types';
import { fetchEventosItemTypeGroup, removeEvents } from '../../../reducer/events-reducer';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
interface propiedades {
    evento: evento;
    fetchEventosItemTypeGroup: (itemTypeGroup: string) => void;
    removeEvents: () => void,
    pasoActualCompra:string
}

type configObjectEventos = {
    eventsReducer: {
        pasoActualCompra:string
    }
}

const Evento = (props: propiedades) => {
    const { evento, fetchEventosItemTypeGroup, removeEvents, pasoActualCompra } = props;
    const navigate = useNavigate();
    const location = useLocation();

    const handleEvent = async (itemTypeGroup: string) => {
        if (evento.itemType === 'group') {
            removeEvents();
            try {
                await fetchEventosItemTypeGroup(itemTypeGroup)
                const searchParams = new URLSearchParams(location.search);
                searchParams.append('group', itemTypeGroup);
                const nuevaUrl = `${location.pathname}?${searchParams.toString()}`;
                navigate(nuevaUrl);

            } catch (error) {
                console.log(error)
            }
        }else if(evento.itemType === 'event' && evento.eventHash){
            navigate(`/evento/${evento.eventHash}/step/${pasoActualCompra}`)
        }
    }

    return (
        <>
            {evento.type === 'imagen' ?
             <EventoImagen evento={evento} handleEvent={handleEvent} /> :
             <EventoTexto evento={evento} handleEvent={handleEvent} />}
        </>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        pasoActualCompra: state.eventsReducer.pasoActualCompra
    }
}

export default connect(
    mapStateToProps,
    { fetchEventosItemTypeGroup, removeEvents }
)(Evento);
