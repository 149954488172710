import { ChangeEvent, useEffect, useState } from "react";
import { proccessPaymentRedSys } from "../../../api/purchase";
import Spinner from "../Spinner";
import { useNavigate } from "react-router-dom";
import {
    Row,
    Col,
    Button,
    Modal, ModalHeader, ModalBody, Input,
  } from 'reactstrap';

type redsysProps = {
    id: string, 
    buttonStyle?: string, 
    bodyStyle?: string,
    boxStyle?:string, 
    inputsStyle?:string, 
    buttonValue?: string, 
    fuc: string, 
    terminal: string, 
    merchantOrder: string, 
    langInsite?: string, 
    showLogo?: string, 
    smallStyle?: boolean, 
    insiteStyle: 'inline' | 'twoRows'
}

declare const getInSiteFormJSON: (insiteJSON:any) => void;
declare const storeIdOper: (event: Event, token: string, errorCode: string, handleRedsysResponse: any) => void;

const Redsys = (props: redsysProps) => {
    const {id, buttonStyle, bodyStyle, boxStyle, inputsStyle, buttonValue, fuc, terminal, merchantOrder, langInsite, showLogo, smallStyle, insiteStyle} = props;
    const insiteJSON = {
        id: id,
        styleButton: buttonStyle ?? '',
        styleBody: bodyStyle ?? '',
        styleBox: boxStyle ?? '',
        styleBoxText: inputsStyle ?? '',
        buttonValue: buttonValue,
        fuc: fuc,
        terminal: terminal,
        order: merchantOrder,
        idioma: langInsite,
        mostrarLogo: showLogo,
        estiloReducido: smallStyle,
        estiloInsite: insiteStyle
    }
    const[loadingPayment, setLoadingPayment] = useState<boolean>(false);
    const navigate = useNavigate();
    const [errorMessage, seterrorMessage] = useState<string | null>(null);
    const [modal, setModal] = useState<boolean>(false);
    
    useEffect(() => {

        const handleMessage = (event: any) => {
            storeIdOper(event,"token", "errorCode", true);
            if(event.data !== 'merchantValidation' && event.data.idOper !== '-1'){
                handleRedsysResponse(event);
            }
        };
        
        setLoadingPayment(true);
        getInSiteFormJSON(insiteJSON);
        setTimeout(function () { 
            setLoadingPayment(false);
        }, 600)

        window.addEventListener("message", handleMessage);  
    
        return () => {
          window.removeEventListener("message", handleMessage);
        };
    }, [])

    const toggle = () => {
        const valueModal = !modal;
        setModal(valueModal);
        if (!valueModal) {
            window.location.reload();
        }
    }

    const handleRedsysResponse = async (event:any) => {
        if(event.data !== null && event.data.idOper !== undefined 
            && event.data.idOper !== "" && event.data.idOper !== "-1" && !loadingPayment) {
            setLoadingPayment(true);
            proccessPaymentRedSys(event.data.idOper)
            .then((res) => {
                if(res.result) {
                  navigate(`/pago-finalizado/debitCreditCard/${res.checkoutData.code}`)
                } else {
                  seterrorMessage(res!.message);
                  toggle();
                }
              })
              .catch((error) => {
                seterrorMessage(error!.message);
                toggle();
              });
        }
       
    };

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader toggle={toggle}>ERROR DE PAGO</ModalHeader>
                <ModalBody className='error-handler'>
                    <p className='text-center error-message'>{errorMessage}</p>
                    <div className='d-flext justify-content-center p-0 mb-2'>
                        <Row className='text-center'>
                            <Col md={12} className='pb-2 pt-2'>
                                <Button className='cancel-bt ps-5 pe-5' onClick={()=>{ toggle(); }}>CERRAR </Button>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
            {loadingPayment &&
                <Col className='position-relative'>
                    <Spinner style={{ 'top': '50%', 'left': '40%' }} />
                </Col>
            }
            {/* <input type="hidden" id="token" onChange={handleChange} ></input> */}
            <Input type='hidden'
                id="token"
            />
            <input type="hidden" id="errorCode" ></input>
            <div id={id} style={{"height": "410px", "display": loadingPayment ? "none" : "block"}}></div>
        </>
    );

};

export default Redsys;