import { connect } from 'react-redux';
import {PurchaseData, datosCompra} from '../../../types/types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Col,Button, Row} from 'reactstrap';
import CompraEventoSingular from '../compra/CompraEventoSingular';
import CompraEventoPlural from '../compra/CompraEventoPlural';
import CompraEventoSeat from '../compra/CompraEventoSeat';
import '../../sass/evento_seleccionado/paso_dos.scss';
import DetalleCompra from '../compra/DetalleCompra';
import TituloEvento from './TituloEvento';
import Timer from './Timer';
import { useNavigate } from 'react-router-dom';

type configObjectDatosComprados = {
    eventsReducer: {
        datosCompraPasoDos: PurchaseData;
        detalleCompra:datosCompra
    }
  }

const PasoDosEvento = ({datosCompraPasoDos, detalleCompra, setTimeOut}:{datosCompraPasoDos:PurchaseData, detalleCompra:datosCompra, setTimeOut: React.Dispatch<React.SetStateAction<boolean>>}) => {
    const navigate = useNavigate();
    return (
        <div className='compra-paso-dos mb-4'>
          {datosCompraPasoDos && <>
            {detalleCompra && <TituloEvento />}
            <Row>
                {datosCompraPasoDos.purchaseType === 'SINGULAR' && <CompraEventoSingular />}
                {datosCompraPasoDos.purchaseType === 'PLURAL' && <CompraEventoPlural />}
                {datosCompraPasoDos.purchaseType === 'SEAT' && <CompraEventoSeat />}
                <Col md={5} className='d-flex justify-content-start flex-column'>
                    {detalleCompra && <DetalleCompra />}

                    {detalleCompra &&
                        <Button
                        className=' p-3 ps-4 pe-4 mt-3'
                        onClick={()=>{
                            const hash = window.location.pathname.split("/")[2];
                            navigate(`/evento/${hash}/step/3`)
                        }}
                        disabled={detalleCompra.ticketsQuantity === 0}> SIGUIENTE PASO <ArrowForwardIosIcon style={{"fontSize":"0.9rem"}}/></Button>}
                    <Button className='back_2 p-3 ps-4 pe-4 mt-4' onClick={()=>{window.history.go(-1)}}><ArrowBackIosIcon style={{"fontSize":"0.9rem"}} /> PASO ANTERIOR</Button>
                    <Timer setTimeOut={setTimeOut} />
                </Col>
            </Row>
          </>}
        </div>
    );
};

const mapStateToProps = (state: configObjectDatosComprados) => {
    return {
        datosCompraPasoDos: state.eventsReducer.datosCompraPasoDos,
        detalleCompra: state.eventsReducer.detalleCompra
    }
}
export default connect(
    mapStateToProps,
    null
)(PasoDosEvento);

