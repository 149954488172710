import React,{Dispatch} from 'react';
import { useTimer } from 'react-timer-hook';
import {parseJWt} from '../../../utilities/utilities';

const Timer = ({ setTimeOut }: { setTimeOut:Dispatch<boolean> }) => {


    const { seconds, minutes } = useTimer({
        expiryTimestamp: new Date(parseJWt(localStorage.getItem('token')!).expiration),
        onExpire: () => {
            setTimeOut(true);
            localStorage.removeItem('token')
        }
    });

    return (
        <div className='mt-4 pb-4'>
            <strong className='timer-text'>
                Tiempo restante de compra: {minutes.toString().padStart(2, '0')}:
                {seconds.toString().padStart(2, '0')}
            </strong>
        </div>
    );
};

export default Timer;
