import { getProvincias } from '../api/geo';
import { getResults } from '../api/contents'
import { Dispatch } from 'redux';
import { EventsData, provincia, textAyuda } from '../types/types';
import { getAyuda, getTelefonoAyuda } from '../api/helpText';
import {GenericObject} from '../types/types';

const initialState = {
    provincias: null,
    textoAyuda: '',
    resultadosBusqueda: null,
    telefonoAyuda:'',
    tituloModalAyuda:'AYUDA',
    error: null,
    mensajeError:''
};

const PROVINCIAS = 'PROVINCIAS'
const TEXTOAYUDA = 'TEXTOAYUDA'
const RESULTADOSBUSQUEDA = 'RESULTADOSBUSQUEDA'
const TELEFONOAYUDA = 'TELEFONOAYUDA'
const TITULOMODALAYUDA = 'TITULOMODALAYUDA'
const ERROR = 'ERROR'


const setProvincias = (provincias: provincia[] | null) => ({ type: PROVINCIAS, payload: provincias });
const setAyuda = (txt: string) => ({ type: TEXTOAYUDA, payload: txt })
const setResultadosBusqueda = (resultados: EventsData | null) => ({ type: RESULTADOSBUSQUEDA, payload: resultados });
const setTelefonoAyuda = (telefono: string) => ({ type: TELEFONOAYUDA, payload: telefono })
const setTituloModalAyuda = (tituloModalAyuda:string) => ({type: TITULOMODALAYUDA, payload: tituloModalAyuda})
const setMensajeError = (errorObjet:GenericObject | null) => ({type: ERROR, payload: errorObjet})

export const fetchProvincias = () => {
    return async (dispatch: Dispatch) => {
        try {
            const res = await getProvincias();
            dispatch(setProvincias(res));
        } catch (error) {
            console.log('Error al obtener provincias:', error);
        }
    }
}

export const fetchTextoAyuda = (path: string, modalTitle?:string) => {
    return async (dispatch: Dispatch) => {
        if (path === '') {
            dispatch(setAyuda(''));
            return;
        }
        try {
            const res: textAyuda = await getAyuda(path);
            dispatch(setAyuda(res.text));

            if(modalTitle){
                dispatch(setTituloModalAyuda(modalTitle))
            }else{
                dispatch(setTituloModalAyuda('AYUDA'))
            }

        } catch (error) {
            console.log('Error al obtener ayuda:', error);
        }
    }
}

export const fetchResultadosBusqueda = (query: string) => {
    return async (dispatch: Dispatch) => {
        if (query !== '') {
            try {
                const res = await getResults(query);
                dispatch(setResultadosBusqueda(res));
            } catch (error) {
                console.log('Error al obtener resultados:', error);
            }
        } else {
            return dispatch(setResultadosBusqueda(null));
        }
    }
}

export const fetchTelefonoAyuda = () => {
    return async (dispatch: Dispatch) => {
        try {
            const res:textAyuda = await getTelefonoAyuda();
            dispatch(setTelefonoAyuda(res.text));
        } catch (error) {
            console.log('Error al obtener telefono:', error);
        }
    }
}

export const mostrarError = (errorObjet:GenericObject | null, mensaje?:string) => {
    return (dispatch: Dispatch) => {
      return dispatch(setMensajeError(errorObjet))
    }
  }

const configReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PROVINCIAS:
            return { ...state, provincias: action.payload };
        case TEXTOAYUDA:
            return { ...state, textoAyuda: action.payload };
        case TITULOMODALAYUDA:
            return { ...state, tituloModalAyuda: action.payload };
        case TELEFONOAYUDA:
                return { ...state, telefonoAyuda: action.payload };
        case RESULTADOSBUSQUEDA:
            return { ...state, resultadosBusqueda: action.payload };
        case ERROR:
            return {...state, error: action.payload}
        default:
            return { ...state };
    }
}

export default configReducer;