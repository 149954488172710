// src/lang/i18n.ts
import { createContext, useContext } from 'react';
import arMessages from './ar.json';
import esMessages from './es.json';

const messages = {
  ar: arMessages,
  es: esMessages,
};

type LanguageContextType = {
    language: 'ar' | 'es';
    setLanguage: (lang: 'ar' | 'es') => void;
  };


const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const language = useLanguage();

  return (
    <LanguageContext.Provider value={language}>
      {children}
    </LanguageContext.Provider>
  );
};

export default messages;
