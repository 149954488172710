import React, { useEffect, useState, Dispatch } from 'react';
import { mostrarError } from '../../reducer/config-reducer'
import { connect } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import '../sass/error_handler.scss';
import {GenericObject} from '../../types/types';

const ErrorHandler = ({ error, mostrarError }: { error: GenericObject | null, mostrarError:Dispatch<GenericObject | null> }) => {

    const msjInicial = 'Ocurrió un error, intentalo de nuevo más tarde';
    const [modal, setModal] = useState(false);
    const [mensaje, setMensaje] = useState<string>(msjInicial);

    const toggle = () => {
        setModal(!modal);
    }

    useEffect(()=>{
        if(error){
            if(error.mensajeAMostrar){
                setMensaje(error.mensajeAMostrar)
            }else{
                setMensaje(msjInicial)
            }
            toggle();
        }
    },[error, mostrarError])

    return (
        <>
            <Modal backdrop={false} className='ayuda modal-error' isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader toggle={toggle}>ERROR</ModalHeader>
                <ModalBody className='error-handler'>
                    <p className='text-center error-message'>{mensaje}</p>
                    <div className='d-flext justify-content-center p-0 mb-2'>
                        <Row className='text-center'>
                            <Col md={12} className='pb-2 pt-2'>
                                <Button className='cancel-bt ps-5 pe-5' onClick={()=>{ toggle(); }}>CERRAR </Button>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: { configReducer: { error: GenericObject | null } }) => {
    return {
        error: state.configReducer.error
    }
}
export default connect(
    mapStateToProps,
    { mostrarError }
)(ErrorHandler);
