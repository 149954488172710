export default class MercadoPagoDeviceId {
    constructor() {
        loadScript();
    }
}

const loadScript = () => {
    const url = `https://www.mercadopago.com/v2/security.js`;
    if (!isScriptExist(url)) {
        const script = document.createElement('script')
        script.src = url;
        document.body.appendChild(script)
    }
}

const isScriptExist = (url: string) => {
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
        if (scripts[i].src === url) return true;
    }
    return false;
}