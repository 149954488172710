import React,{useEffect} from 'react';
import {Row, Col, Container} from 'reactstrap';
import { useParams } from 'react-router-dom';
import Cash from '../components/pagofinalizado/Cash';
import DebitCreditCard from '../components/pagofinalizado/DebitCreditCard';
import '../sass/pay_success.scss';

const PagoFinalizado = () => {
    const params = useParams();
    const {checkoutType, paymentMethod, additionalCode} = params;

    useEffect(()=>{
        localStorage.removeItem('token')
        setTimeout(()=>{
            window.scrollTo(0, 0);
        },500)
    },[])

    return (
        <Container className='text-center pay-success'>
            <Row>
                <Col xs={{ size: 4, offset: 4 }} className='mt-4'>
                    <img src="/images/confirmado.svg" alt="icono confirmado" className='img-fluid logo-confirmado' />
                </Col>
            </Row>
            <Row>
                <Col>
                    {checkoutType === 'cash' && additionalCode && paymentMethod &&
                        <Cash additionalCode={additionalCode} paymentMethod={paymentMethod} />}
                    {(checkoutType === 'debitCreditCard' || checkoutType === 'freePaid') &&
                        <DebitCreditCard additionalCode={additionalCode}/>}
                </Col>
            </Row>
        </Container>
    );
};

export default PagoFinalizado;