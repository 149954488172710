import React from 'react';
import { connect } from 'react-redux';
import {Row,Col} from 'reactstrap';
import '../../sass/pasos_compra.scss';
import { eventoSeleccionado } from '../../../types/types';

type pasoCompra = {
    id:string,
    title:string
}

type propiedades = {
    pasosCompra: pasoCompra[],
    pasoActualCompra:string,
    eventoSeleccionado: eventoSeleccionado;
}
export type configObjectEventos = {
    eventsReducer: propiedades
}

const pasosCompra = (props:propiedades) => {
    return (
        <Row className='pasoscompra ps-0'>
            {props.eventoSeleccionado.sessions && props.pasosCompra.map((paso, index)=>{
                return (
                <Col key={index} xs={paso.id === props.pasoActualCompra ? 6 : 2} className='p-0'>
                    <div className={paso.id === props.pasoActualCompra ? 'paso paso-actual' : 'paso'}>{paso.id === props.pasoActualCompra ? paso.title : paso.id}</div>
                </Col>)
            })}
            {!props.eventoSeleccionado.sessions &&
                <Col className='text-center pt-3 proximamente'>PROXIMAMENTE</Col>
            }
        </Row>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        pasosCompra: state.eventsReducer.pasosCompra,
        pasoActualCompra: state.eventsReducer.pasoActualCompra,
        eventoSeleccionado: state.eventsReducer.eventoSeleccionado,
    }
}

export default connect(
    mapStateToProps,null
)(pasosCompra);

