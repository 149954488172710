import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { getLangValue } from '../../api/config';

const EventosPopulares = () => {
    
    const [lang] = useState<string>(`${getLangValue("lang")}`);

    useEffect(()=>{

        if(lang === 'es_ES'){
            let alternateAr = document.getElementById("alternate_ar") as HTMLLinkElement;
            alternateAr.href = "https://www.entradaweb.com.ar";

            let alternateEs = document.getElementById("alternate_es") as HTMLLinkElement;
            alternateEs.href = "https://www.entradaweb.es/los-30-eventos-mas-populares";

            let alternateDefault = document.getElementById("alternate_default") as HTMLLinkElement;
            alternateDefault.href = "https://www.entradaweb.com";

            const script = document.createElement('script')
            script.type = 'application/ld+json';
            script.text = JSON.stringify({
                "@context": "https://schema.org",
                "@type": "Article",
                "url": "https://www.entradaweb.es/los-30-eventos-mas-populares",
                "name": "Los eventos más populares en España",
                "image": "https://imgs.entradaweb.es/los-eventos-mas-populares.jpg",
                "description": "Descubre los eventos más populares en España.",
                "author": "EntradaWeb",
                "publisher": "EntradaWeb",
                "datePublished": "2024-07-05",
                "potentialAction": [{
                "@type": "ReadAction",
                "target": "https://www.entradaweb.es/los-30-eventos-mas-populares"
                }],
                "articleBody": "Descubre los eventos más emblemáticos y emocionantes que España tiene para ofrecer a lo largo del año. Desde festivales de música y fiestas tradicionales hasta eventos deportivos de renombre internacional, esta lista te llevará a través de una experiencia cultural única. Cada evento no solo refleja la rica historia y diversidad cultural de España, sino que también atrae a visitantes de todo el mundo."
            });
            document.head.appendChild(script)
        }

    },[])

    return (<>
     {lang === 'es_ES' && <Container>
        <Row>
            <Col className='text-start mt-4'>
                                
                <h1 className='text_2 mb-4' style={{"textAlign": "center"}}>Los 30 eventos más Populares en España</h1>
                <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Descubre los eventos más emblemáticos y emocionantes que España tiene para ofrecer a lo largo del año. Desde festivales de música y fiestas tradicionales hasta eventos deportivos de renombre internacional, esta lista te llevará a través de una experiencia cultural única. Cada evento no solo refleja la rica historia y diversidad cultural de España, sino que también atrae a visitantes de todo el mundo.</p>
                <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Explora festividades como la Feria de Abril en Sevilla, con su vibrante ambiente andaluz, o las impresionantes Fallas de Valencia, conocidas por sus monumentales esculturas de cartón piedra. Sumérgete en la pasión del fútbol con el famoso Clásico entre el Real Madrid y el FC Barcelona, o déjate llevar por la música en el Primavera Sound de Barcelona y el Mad Cool Festival en Madrid.</p>
                <Row className='mb-4'>
                    <Col className='d-flex justify-content-center'>
                        <img src="https://imgs.entradaweb.es/los-eventos-mas-populares.jpg" className='img-fluid' alt="Los eventos mas populares" />
                    </Col>
                </Row>
                <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Este artículo te llevará a través de cada evento con una descripción breve y una visión de por qué estos eventos son tan queridos y populares entre los locales y los viajeros por igual.</p>
                
                <h4 className="text_2 col-md-8 mt-3"><a style={{"textDecoration": "none", "color": "#34495E"}} href="#los-eventos-mas-populares">Los eventos más populares en España</a></h4>
                <h4 className="text_2 col-md-8 mt-3"><a style={{"textDecoration": "none", "color": "#34495E"}} href="#por-que-vender-entradas">¿Por qué vender entradas para los eventos más populares en España?</a></h4>
                <h4 className="text_2 col-md-8 mt-3"><a style={{"textDecoration": "none", "color": "#34495E"}} href="#por-que-vender-en-entradda-web">¿Por qué vender entradas en EntradaWeb?</a></h4>
                
                <h2 className="text_2 col-md-8 mt-3" id="los-eventos-mas-populares">Los eventos más populares en España</h2>    
                    <ol>
                        <li>
                            <h5>Feria de Abril (Sevilla)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Celebración andaluza con casetas, flamenco y tradición.</p>
                        </li>
                        <li>
                            <h5>Fallas de Valencia (Valencia)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Festividad con monumentos falleros y la cremà.</p>
                        </li>
                        <li>
                            <h5>Semana Santa (varias ciudades)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Procesiones religiosas destacadas en ciudades como Sevilla, Málaga y Valladolid.</p>
                        </li>
                        <li>
                            <h5>San Fermín (Pamplona)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Famoso encierro de toros y fiestas populares.</p>
                        </li>
                        <li>
                            <h5>La Tomatina (Buñol, Valencia)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Batalla de tomates más grande del mundo.</p>
                        </li>
                        <li>
                            <h5>Primavera Sound (Barcelona)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Festival de música indie y alternativa.</p>
                        </li>
                        <li>
                            <h5>Sónar (Barcelona)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Festival de música electrónica y experimental.</p>
                        </li>
                        <li>
                            <h5>Mad Cool Festival (Madrid)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Festival de música rock, pop y alternativa.</p>
                        </li>
                        <li>
                            <h5>Bienal de Flamenco (Sevilla)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Evento de flamenco con espectáculos y actuaciones.</p>
                        </li>
                        <li>
                            <h5>Carnaval de Cádiz (Cádiz)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Celebración con chirigotas, comparsas y mucha sátira.</p>
                        </li>
                        <li>
                            <h5>Aste Nagusia (Semana Grande) (Bilbao)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Semana de fiestas con actividades culturales, conciertos y eventos deportivos.</p>
                        </li>
                        <li>
                            <h5>Sant Jordi (Barcelona)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Día del libro y la rosa con venta de libros y flores por las calles.</p>
                        </li>
                        <li>
                            <h5>Festival Internacional de Cine de San Sebastián (San Sebastián)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Festival de cine de renombre internacional.</p>
                        </li>
                        <li>
                            <h5>Córdoba en Mayo (Córdoba)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Festival de los patios y la cruces de mayo.</p>
                        </li>
                        <li>
                            <h5>Festival Internacional de Benicàssim (FIB) (Benicàssim)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Festival de música indie, rock y electrónica.</p>
                        </li>
                        <li>
                            <h5>Muestra Internacional de Teatro Contemporáneo (Mérida)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Festival de teatro clásico y contemporáneo en el Teatro Romano de Mérida.</p>
                        </li>
                        <li>
                            <h5>Semana Grande de Bilbao (Bilbao)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Semana de fiestas con conciertos, fuegos artificiales y eventos deportivos.</p>
                        </li>
                        <li>
                            <h5>Feria de Málaga (Málaga)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Celebración con feria de día y feria de noche, con actividades culturales y tradicionales.</p>
                        </li>
                        <li>
                            <h5>Festival de Patios de Córdoba (Córdoba)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Concurso de los patios más bellos decorados con flores.</p>
                        </li>
                        <li>
                            <h5>Festival de la Guitarra de Córdoba (Córdoba)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Evento dedicado a la guitarra flamenca y clásica con conciertos y actividades.</p>
                        </li>
                        <li>
                            <h5>Festival Internacional de Teatro Clásico (Almagro)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Celebración del teatro clásico español en julio.</p>
                        </li>
                        <li>
                            <h5>Jazzaldia (San Sebastián)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Festival de jazz con actuaciones en diversos escenarios de la ciudad.</p>
                        </li>
                        <li>
                            <h5>Arde Lucus (Lugo)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Recreación histórica de la vida romana y galaica en Lugo.</p>
                        </li>
                        <li>
                            <h5>Feria de Abril de Cataluña (Barcelona)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Réplica de la Feria de Abril de Sevilla en Cataluña.</p>
                        </li>
                        <li>
                            <h5>Noche de San Juan (varias ciudades)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Celebración del solsticio de verano con hogueras y rituales.</p>
                        </li>
                        <li>
                            <h5>Festival de Verano de El Escorial (El Escorial)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Festival de música clásica y danza en el Real Sitio de San Lorenzo de El Escorial.</p>
                        </li>
                        <li>
                            <h5>Festival de las Cruces de Mayo (Granada)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Celebración con cruces decoradas y actividades culturales.</p>
                        </li>
                        <li>
                            <h5>Festival de la Luna Mora (Guaro, Málaga)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Festival de música y cultura árabe-andalusí bajo la luz de la luna.</p>
                        </li>
                        <li>
                            <h5>Fiesta del Patrón San Isidro (Madrid)</h5>
                            <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Celebración de San Isidro con conciertos, eventos deportivos y actividades culturales en toda la ciudad.</p>
                        </li>
                    </ol>
                <h2 className="text_2 mt-3" id="por-que-vender-entradas">Por qué vender entradas para los eventos más populares en España</h2>
                <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Vender entradas para los eventos más populares en España representa una oportunidad emocionante y lucrativa para organizadores y promotores. Estos eventos atraen a una amplia audiencia tanto local como internacional debido a su singularidad cultural, artística y deportiva. Desde festivales de música emblemáticos como el Primavera Sound en Barcelona hasta celebraciones tradicionales como la Feria de Abril en Sevilla, cada evento ofrece una experiencia única que atrae a miles de asistentes ávidos de participar en la vibrante cultura española.</p>

                <p style={{"color": "#34495E", "lineHeight": "1.6"}}>La venta de entradas no solo es crucial para la gestión financiera del evento, sino que también permite a los organizadores controlar la asistencia y planificar de manera eficiente la logística. Las plataformas modernas de venta de entradas como EntradaWeb facilitan este proceso al ofrecer herramientas avanzadas para la gestión de eventos, desde la publicación inicial hasta el seguimiento de ventas y la gestión de accesos. Además, vender entradas por Internet amplía el alcance del evento, permitiendo que personas de todo el mundo puedan participar, lo que potencia la visibilidad y el éxito global del evento.</p>


                <h2 className="text_2 mt-3" id="por-que-vender-en-entradda-web">¿Por qué vender entradas en EntradaWeb?</h2>
                <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Vender entradas en <a href="https://www.entradaweb.es/">EntradaWeb</a> ofrece numerosas ventajas para organizadores y promotores de eventos en España:</p>
                    <ol>
                        <li><strong>Facilidad de Uso:</strong> EntradaWeb proporciona una plataforma intuitiva y fácil de usar para la gestión completa de eventos y venta de entradas.</li>
                        <li><strong>Amplio Alcance:</strong> Permite llegar a una audiencia más amplia a nivel nacional e internacional mediante la venta de entradas en línea.</li>
                        <li><strong>Personalización:</strong> Los organizadores pueden configurar precios, tipos de entradas, descuentos y promociones adaptadas a las necesidades específicas de cada evento.</li>
                        <li><strong>Seguridad y Confianza:</strong> Utiliza tecnología avanzada para garantizar la seguridad de los datos personales y financieros de los usuarios durante las transacciones en línea.</li>
                        <li><strong>Soporte Integral:</strong> Ofrece soporte dedicado y asistencia continua para ayudar a los organizadores en todo momento.</li>
                        <li><strong>Informes y Analíticas:</strong> Proporciona reportes detallados y analíticas para mejorar la gestión de ventas y marketing del evento.</li>
                    </ol>
                <p style={{"color": "#34495E", "lineHeight": "1.6"}}>En resumen, vender entradas en EntradaWeb facilita la gestión del evento, amplía su alcance y garantiza una experiencia segura y satisfactoria tanto para organizadores como para asistentes.</p>
            </Col>
        </Row>
     </Container>}
    </>);
};
export default EventosPopulares;