import { Client } from "./baseurl";

export const getBackgroundImage = async() => {
    const response = await Client.get(`/home`);
    return response.data;
}

export const getLandingPageBySlug = async(slug: string) => {
    const response = await Client.get(`/home/custom-url?path=${slug}`);
    return response.data;
}
