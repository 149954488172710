import { getEvents, getEventsItemTypeGroup, getSelectedEvent } from '../api/events';
import { purchaseInfo, comprarPasoDos, getBuyerData } from '../api/purchase'
import { evento, PurchaseData } from '../types/types';
import { Dispatch } from 'redux';
const initialState = {
  eventos: null,
  eventoSeleccionado: null,
  pasosCompra: [{ id: "1", title: "Paso 1: comienza la COMPRA" }, { id: "2", title: "Paso 2: Agrega tus LUGARES" }, { id: "3", title: "Paso 3: Ingresa tus DATOS" }, { id: "4", title: "Paso 4: Realiza el PAGO" }],
  pasoActualCompra: "1",
  idVendedor: null,
  token: null,
  datosCompraPasoDos: null,
  detalleCompra: null,
  fueraDeVenta: false,
  loaderCheckCardNumber:false,
  cardNumber: null
};

const EVENTOS = 'EVENTOS'
const EVENTOSELECCIONADO = 'EVENTOSELECCIONADO'
const IDVENDEDOR = 'IDVENDEDOR'
const PASOACTUALCOMPRAR = 'PASOACTUALCOMPRA'
const TOKEN = 'TOKEN'
const DATOSCOMPRAPASODOS = 'DATOSCOMPRAPASODOS'
const DETALLECOMPRA = 'DETALLECOMPRA'
const FUERADEVENTA = 'FUERADEVENTA'
const LOADERCHECKCARDNUMBER = 'LOADERCHECKCARDNUMBER'
const CARDNUMBER = 'CARDNUMBER';

const setEventos = (eventos: evento[] | null) => ({ type: EVENTOS, payload: eventos })
const setEventoSeleccionado = (evento: evento | null) => ({ type: EVENTOSELECCIONADO, payload: evento })
const setIdVendedor = (idVendedor: string | null) => ({ type: IDVENDEDOR, payload: idVendedor })
const setNuevoPaso = (paso: string) => ({ type: PASOACTUALCOMPRAR, payload: paso })
const setDatosCompraPasoDos = (datosCompra: PurchaseData) => ({ type: DATOSCOMPRAPASODOS, payload: datosCompra })
const setDetalleCompra = (detalleCompra: any) => ({ type: DETALLECOMPRA, payload: detalleCompra })
const setFueraDeVenta = (fuera:boolean) => ({type:FUERADEVENTA, payload:fuera})
const setLoaderCheckCardNumber = (loading:boolean) => ({type:LOADERCHECKCARDNUMBER, payload: loading})
const setCardNumber = (cardNumber: string | null) =>({type: CARDNUMBER, payload: cardNumber})

export const fetchEventos = (keywords: string | null, date: string | null, provinceId: string |  null, place:string | null, localidadId: string | null) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await getEvents(keywords, date, provinceId, place, localidadId);
      dispatch(setEventos(res.events));
      return res;
    } catch (error) {
      console.log('Error al obtener resultados:', error);
      throw error;
    }
  };
};

export const fetchEventosItemTypeGroup = (groupId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await getEventsItemTypeGroup(groupId);
      dispatch(setEventos(res.events));
      return res;
    } catch (error) {
      console.log('Error al obtener resultados:', error);
      throw error;
    }
  };
};

export const fetchEventoSeleccionado = (eventoHash: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await getSelectedEvent(eventoHash);
      if(!res){
        dispatch(setFueraDeVenta(true))
      }else{
        dispatch(setEventoSeleccionado(res));
        dispatch(setFueraDeVenta(false))
      }
      return res;
    } catch (error) {
      console.log('Error al obtener el evento:', error);
      throw error;
    }
  };
};

export const fetchCambiarNombrePaso = (shouldChangeName: boolean) => {
  return (dispatch: Dispatch) => {
    const title = shouldChangeName ? 'Paso 1: Selecciona FECHA y HORA' : 'Paso 1: comienza la COMPRA';
    dispatch({ type: 'CAMBIAR_NOMBRE_PASO', payload: { title } });
  };
}

export const removeEvents = () => {
  return (dispatch: Dispatch) => {
    return dispatch(setEventos(null))
  }
}

export const removeDetalleCompra = () => {
  return (dispatch: Dispatch) => {
    return dispatch(setDetalleCompra(null))
  }
}

export const cambiarLoaderCheckCardNumber = (loading:boolean) => {
  return (dispatch: Dispatch) => {
    return dispatch(setLoaderCheckCardNumber(loading))
  }
}

export const removeEventoSeleccionado = () => {
  return (dispatch: Dispatch) => {
    dispatch(setFueraDeVenta(false))
    return dispatch(setEventoSeleccionado(null))
  }
}

export const setVendedor = (idVendedor: string | null) => {
  return (dispatch: Dispatch) => {
    return dispatch(setIdVendedor(idVendedor))
  }
}

export const setCard = (cardNumber: string | null) => {
  return (dispatch: Dispatch) => {
    return dispatch(setCardNumber(cardNumber))
  }
}

export const irAlPasoDos = (code: string, seller: string, referrer: string | null, cardNumber: string | null, onlyReload?:boolean) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await comprarPasoDos(code, seller,referrer, cardNumber);
      if(!onlyReload){
        dispatch(setNuevoPaso('2'))
      }
      dispatch(setDatosCompraPasoDos(res))
      //dispatch(setToken(res.token))
      //localStorage.setItem('token', res.token)
      return res;
    } catch (error) {
      console.log('Error al paso dos evento:', error);
      return error;
    }
  };
}

export const irAlPasoTres = () => {
  return (dispatch: Dispatch) => {
    return dispatch(setNuevoPaso('3'))
  }
}

export const irAlPasoCuatro = (type:string) => {
  return (dispatch: Dispatch) => {

    return dispatch(setNuevoPaso('4'))
  }
}

export const resetPasosCompra = () => {
  return (dispatch: Dispatch) => {
    return dispatch(setNuevoPaso("1"))
  }
}

export const fetchDetalleCompra = () => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await purchaseInfo();
      dispatch(setDetalleCompra(res));
      return res;
    } catch (error) {
      console.log('Error al obtener el evento:', error);
      throw error;
    }
  };
}

export const fetchBuyerData = () => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await getBuyerData();
      dispatch(setDetalleCompra(res));
      return res;
    } catch (error) {
      console.log('Error al obtener el evento:', error);
      throw error;
    }
  };
}

const eventsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case EVENTOS:
      return { ...state, eventos: action.payload };
    case EVENTOSELECCIONADO:
      return { ...state, eventoSeleccionado: action.payload }
    case 'CAMBIAR_NOMBRE_PASO':
      return {
        ...state,
        pasosCompra: state.pasosCompra.map((paso, index) => {
          if (index === 0) {
            return {
              ...paso,
              title: action.payload.title,
            };
          }
          return paso;
        }),
      };
    case IDVENDEDOR:
      return { ...state, idVendedor: action.payload };
    case CARDNUMBER:
      return { ...state, cardNumber: action.payload };
    case PASOACTUALCOMPRAR:
      return { ...state, pasoActualCompra: action.payload }
    case TOKEN:
      return { ...state, token: action.payload }
    case DATOSCOMPRAPASODOS:
      return { ...state, datosCompraPasoDos: action.payload }
    case DETALLECOMPRA:
      return { ...state, detalleCompra: action.payload }
    case FUERADEVENTA:
      return { ...state, fueraDeVenta: action.payload }
    case LOADERCHECKCARDNUMBER:
      return { ...state, loaderCheckCardNumber: action.payload }
    default:
      return { ...state };
  }
}

export default eventsReducer;