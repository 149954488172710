import React, { useCallback, useEffect } from 'react';
import { fetchEventos, removeEvents, resetPasosCompra, fetchEventosItemTypeGroup, removeEventoSeleccionado } from '../../reducer/events-reducer';
import { connect } from 'react-redux';
import { evento } from '../../types/types';
import Spinner from '../components/Spinner';
import Evento from '../components/eventos/Evento';
import {
    Container,
    Row,
    Col,
    Button
} from 'reactstrap';
import 'moment/locale/es';
import Masonry from "react-responsive-masonry"
import { FormattedMessage } from 'react-intl';

export type configObjectEventos = {
    eventsReducer: {
        eventos: evento[];
    }
}

interface propiedades {
    eventos: evento[];
    fetchEventos: (keywords: string | null, date: string | null, provinceId: string | null, place: string | null, localidadId: string | null) => void;
    removeEvents: () => void,
    resetPasosCompra: () => void
    fetchEventosItemTypeGroup: (itemTypeGroup: string) => void;
    removeEventoSeleccionado: () => void;
}

const Eventos = (props: propiedades) => {

    const { fetchEventos, removeEvents, resetPasosCompra, removeEventoSeleccionado, fetchEventosItemTypeGroup, eventos } = props;


    const goBackHandler = () => {
        window.location.href = window.location.origin;
    }

    const call = useCallback(async () => {
        const URLparams = new URLSearchParams(window.location.search)
        let k: string | null = null;
        let p: string | null = null;
        let d: string | null = null;
        let pl: string | null = null;
        let lo: string | null = null;
        let itemTypeGroup: string | null = null;

        if (URLparams.get('group')) {
            itemTypeGroup = URLparams.get('group');
            await fetchEventosItemTypeGroup(itemTypeGroup!)
        } else {
            if (URLparams.get('k')) {
                k = URLparams.get('k');
            }
            if (URLparams.get('p')) {
                p = URLparams.get('p');
            }
            if (URLparams.get('d')) {
                d = URLparams.get('d');
            }
            if (URLparams.get('pl')) {
                pl = URLparams.get('pl');
            }
            if (URLparams.get('lo')) {
                lo = URLparams.get('lo');
            }
            try {
                await fetchEventos(k, d, p, pl, lo);
            } catch (error) {
                console.log(error)
            }
        }
    }, [fetchEventos, fetchEventosItemTypeGroup]);


    useEffect(() => {

        localStorage.removeItem('token');
        resetPasosCompra();

        call();
        removeEventoSeleccionado();
        if (eventos) {
            removeEvents();
        }
        // eslint-disable-next-line
    }, [resetPasosCompra, removeEvents, removeEventoSeleccionado, call]);



    const detectEvent = useCallback(() => {
        removeEvents()
        call()
    }, [removeEvents, call])

    useEffect(() => {
        window.addEventListener("popstate", detectEvent);
        return () => window.removeEventListener("popstate", detectEvent);
    }, [detectEvent])

    useEffect(()=>{
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500)
    },[props.eventos])

    const NoHayResultados = () => {
        return (<Container className='text-center pb-4 without-results'>
            <div className='mt-5'></div>
            <Row>
                <Col xs={{ size: 4, offset: 4 }} className='mt-4'>
                    <img src="/images/icono-busqueda-01.svg" alt="icono de busqueda sin resultados" className='img-fluid icon-without-result' />
                </Col>
            </Row>
            <Row>
                <Col><h1 className='text_3 fs-22 pt-3'>¡No encontramos ningún resultado!</h1></Col>
            </Row>
            <Row>
                <Col className='text-center ps-4 pe-4 fs-12 ps-5 pt-2' style={{ "lineHeight": "22px" }}>
                    <p><FormattedMessage id="event_not_found_text" /></p>
                </Col>
            </Row>
            <Row>
                <Col className='mt-5'><Button className='back_3' onClick={goBackHandler}> VOLVER</Button></Col>
            </Row>
        </Container>)
    }

    return (
        <Container className='list-events'>
            {!props.eventos && <div className='vh-100'><Spinner style={{ left: "50%", top: "30vh", marginLeft: "-40px" }} /></div>}
            <Row>
            <>{props.eventos &&
                <>{props.eventos.length > 0 ? (
                    <Masonry columnsCount={2}>
                        {props.eventos.map((evento) => (
                            <div className='p-2 position-relative' key={evento.eventHash}>
                                <Evento evento={evento} />
                            </div>
                        ))}
                    </Masonry>
                ) : (
                    <NoHayResultados />
                )}</>}</>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        eventos: state.eventsReducer.eventos
    }
}
export default connect(
    mapStateToProps,
    { fetchEventos, removeEvents, resetPasosCompra, fetchEventosItemTypeGroup, removeEventoSeleccionado }
)(Eventos);

