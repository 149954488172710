import React from 'react';import {
    Row,
    Col,
} from 'reactstrap';
import DateRangeIcon from '@mui/icons-material/DateRange';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';

const FechaYHoraSimple = (propiedades: { fecha: string, hora: string, status: string, showBadgetAgotado: boolean }) => {
    return (
        <>
            <Row className='fecha-hora-simple row-info'>
                <Col xs={6} className='d-flex'>
                    <DateRangeIcon className='me-2' />
                    <div>{propiedades.fecha}</div>
                </Col>
                <Col xs={6} className='d-flex'>
                    <WatchLaterOutlinedIcon className='me-2' />
                    {propiedades.hora}
                </Col>
            </Row>
            <Row>
                <Col xs={12} className='d-flex'>
                    {propiedades.status === "0" && propiedades.showBadgetAgotado && <><div className="badge badge-agotado w-100">AGOTADO</div></>}
                </Col>
            </Row>
            
        </>
    )
}

export default FechaYHoraSimple;