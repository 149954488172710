import { connect } from 'react-redux';
import {PurchaseData, datosCompra} from '../../../types/types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Col,Button, Row} from 'reactstrap';
import CompraEventoSingular from '../compra/CompraEventoSingular';
import CompraEventoPlural from '../compra/CompraEventoPlural';
import CompraEventoSeat from '../compra/CompraEventoSeat';
import '../../sass/evento_seleccionado/paso_dos.scss'
import TituloEvento from './TituloEvento';
import { useNavigate } from 'react-router-dom';

type configObjectDatosComprados = {
    eventsReducer: {
        datosCompraPasoDos: PurchaseData;
        detalleCompra:datosCompra
    }
  }

const PasoDosEvento = ({datosCompraPasoDos, detalleCompra}:{datosCompraPasoDos:PurchaseData, detalleCompra:datosCompra}) => {
    const navigate = useNavigate();
    return (
        <div className='compra-paso-dos'>
          {datosCompraPasoDos && <>
            {detalleCompra && <TituloEvento paso={2}/>}
                <Row>
                    <Col>
                        <h4 className='add-places-text subtitle'>Agrega Tus Lugares</h4>
                    </Col>
                </Row>

                {datosCompraPasoDos.purchaseType === 'SINGULAR' && <CompraEventoSingular />}
                {datosCompraPasoDos.purchaseType === 'PLURAL' && <CompraEventoPlural />}
                {datosCompraPasoDos.purchaseType === 'SEAT' && <CompraEventoSeat />}
                <Col className='d-flex justify-content-between steps-buttons flex-column'>
                    {detalleCompra &&
                        <Button className='siguiente-paso'
                        onClick={()=>{
                            const hash = window.location.pathname.split("/")[2];
                            navigate(`/evento/${hash}/step/3`)
                        }}
                        disabled={detalleCompra.ticketsQuantity === 0}> Siguiente Paso <ArrowForwardIosIcon /></Button>}
                        <Button className='back_2' onClick={()=>{window.history.go(-1)}}><ArrowBackIosIcon /> Paso Anterior</Button>
                </Col>
          </>}
        </div>
    );
};

const mapStateToProps = (state: configObjectDatosComprados) => {
    return {
        datosCompraPasoDos: state.eventsReducer.datosCompraPasoDos,
        detalleCompra: state.eventsReducer.detalleCompra
    }
}
export default connect(
    mapStateToProps,
   null
)(PasoDosEvento);

