import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './mobile/App';
import AppDesktop from './desktop/App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './reducer/store';
import { isMobile } from 'react-device-detect';
import { LanguageProvider } from './lang/i18n';
import { IntlProvider } from 'react-intl';
import messages from './lang/i18n';
import { setHeadersValues, getLangValue } from './api/config';

const RootComponent = () => {
  const [idioma, setIdioma] = useState<'ar' | 'es' | ''>('');

  useEffect(() => {
    if (isMobile) {
      document.body.classList.add('App_mobile');
    } else {
      document.body.classList.add('App_desktop');
    }
    const lang = getLangValue('lang');
    //setHeadersValues(lang);
    var referrer = document.referrer;

    if (referrer.length <= 0) {
      if (lang === 'es_ES') {
        referrer = "https://www.entradaweb.es"
      } else {
        referrer = "https://www.entradaweb.com.ar/"
      }
    }

    if (!localStorage.getItem('referrer')) {
      localStorage.setItem('referrer', referrer);
    }

    if (lang === 'es_ES') {
      setIdioma('es');

      var s = document.createElement("script");
            s.type = `text/javascript`;
            s.src = "https://sis-t.redsys.es:25443/sis/NC/sandbox/redsysV3.js";
            //s.src = "https://sis.redsys.es/sis/NC/redsysV3.js";
            document.head.appendChild(s);
    } else {
      setIdioma('ar');
    }

    return () => {
      if (isMobile) {
        document.body.classList.remove('App_mobile');
      } else {
        document.body.classList.remove('App_desktop');
      }
    };
  }, []);

  return (
    <>{idioma !== '' && <LanguageProvider>
      <Provider store={store}>
        <IntlProvider locale={idioma} messages={messages[idioma]}>
          <BrowserRouter>
            {isMobile ? <App /> : <AppDesktop />}
          </BrowserRouter>
        </IntlProvider>
      </Provider>
    </LanguageProvider>}</>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<RootComponent />);
