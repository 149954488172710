import { useEffect, useState } from 'react';
import Spinner from '../components/Spinner';
import '../sass/evento_seleccionado.scss';
import '../sass/general.scss';
import '../sass/evento_seleccionado/paso_cuatro.scss';
import { executeTransfer } from '../../api/transfer';
import { Col, Container, Row } from 'reactstrap';
import { getSellerPurchase } from '../../api/sellerPurchase';
import { getLangValue } from '../../api/config';
import DateRangeIcon from '@mui/icons-material/DateRange';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import TipoPagoTarjeta from '../components/compra/TipoPagoTarjeta';
import { Table } from 'antd';



const TerminarCompraVendedor = () => {
    
    const [sellerPurchase, setSellerPurchase] = useState<any | null>(null);
    const [errorMessage, setErrorMessage] = useState<any | null>(null);
    const lang = getLangValue('lang');
    const [purchaseCode, setPurchaseCode] = useState<string | null>("");
    const [viewComissionText, setViewComissionText] = useState<boolean>(true);
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        setPurchaseCode(code);
        (async () => {
            if(code) {
                try {
                    const response = await getSellerPurchase(code);
                    if (response) {
                        setSellerPurchase(response);
                    }   
                } catch (error: any) {
                    setErrorMessage(error!.message)
                }
            }
        })()
    }, [])

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
        },
        {
            title: 'Sector',
            dataIndex: 'sector',
            key: 'sector',
        },
        {
            title: 'LUGAR',
            dataIndex: 'place',
            key: 'place',
        },
        {
            title: 'Precio',
            dataIndex: 'price',
            key: 'price'
        }
    ];

    const data = sellerPurchase ? sellerPurchase.info.tickets.map((ticket:any, index:number) => ({
        ...ticket,
        key: index.toString(),
    })) : null;

    const handlePasoAnterior = (stepNumber:number): void => {};
    const handleViewComissionText = (): void => { setViewComissionText(false);}

    return (
        <Container className=''>
            {sellerPurchase && !errorMessage &&
                <>
                    <div className='evento-seleccionado'>
                        <Row className='pasoscompra ps-0'>
                            <Col className='p-0'>
                                <div className='paso paso-actual paso-terminar-compra'>Realiza el PAGO</div>
                            </Col>
                        </Row>
                        <div className='compra-paso-cuatro'>
                            <div><p className="h4 titulo_evento_en_paso text_2" dangerouslySetInnerHTML={{ __html: sellerPurchase.eventName }} /></div>
                            <div className='d-flex justify-content-center time-date-wrapper'>
                                <div className='d-flex text-center'>
                                    <DateRangeIcon />
                                    <div className=''>{sellerPurchase.info.eventDate}</div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center time-date-wrapper'>
                                <div className='d-flex text-center'>
                                    <WatchLaterOutlinedIcon />
                                    <div className='text-center'>{sellerPurchase.info.eventHour}</div>
                                </div>
                            </div>
                            <div className='location-wrapper'>
                                <div className='text-center'>
                                    <div className=''><LocationOnOutlinedIcon />{sellerPurchase.info.address}</div>
                                </div>
                            </div>
                            <div className='mb-2'></div>
                            <div className='mb-4'></div>
                            <TipoPagoTarjeta handleViewComissionText={handleViewComissionText} presetedCardNumber={null} purchaseCode={purchaseCode} />
                            <Row className="mt-4 mb-4">
                                <Col>
                                    <h4 className='subtitle'>Detalle de tu Compra</h4>
                                    <Table className='detalle-compra-table' columns={columns} dataSource={data} pagination={false} />
                                    <Row className='total-detalle-compra pt-2 pb-2'>
                                        <Col xs={8} className='ps-3'> SUBTOTAL (por cta. y orden) </Col>
                                        <Col xs={4} className='text-end'> {sellerPurchase.info.totalTickets} </Col>
                                    </Row>
                                    <Row>
                                        <hr className='detalle-compra-hr'/>
                                    </Row>
                                    <Row className='total-detalle-compra pt-2 pb-2'>
                                        <Col xs={8} className='ps-3'> Servicio Venta Internet {lang === 'es_AR' && (sellerPurchase.info.commission !== "$ 0,00") && <><br/><small>(este cargo puede variar según el medio de pago)</small></>}</Col>
                                        <Col xs={4} className='text-end'> {sellerPurchase.info.commission} </Col>
                                    </Row>
                                    <Row>
                                        <hr className='detalle-compra-hr'/>
                                    </Row>
                                    {sellerPurchase.info.amountDiscount < 0 && <>
                                        <Row className='total-detalle-compra pt-2 pb-2'>
                                            <Col xs={6} className='ps-3'> {sellerPurchase.info.discountName} </Col>
                                            <Col xs={6} className='text-end'>{sellerPurchase.info.totalDiscount}</Col>
                                        </Row>
                                    </>}
                                    <Row className='total-detalle-compra pt-2 pb-2'>
                                        <Col xs={6} className='ps-3'> TOTAL </Col>
                                        <Col xs={6} className='text-end'> {sellerPurchase.info.totalToPaid} </Col>
                                    </Row>
                                    <Row>
                                        <hr className='detalle-compra-hr'/>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
            </>}
            {!sellerPurchase && !errorMessage &&
            <div className='vh-100'>
                <h1 className='mt-5 text_3 general-title text-center'>Estamos buscando</h1>
                <h1 className='text_3 general-title text-center'>tu compra...</h1>
                <h1 className='text_3 general-title text-center'>Aguarda unos segundos...</h1>
                <Spinner className='text-center' style={{"top":"50%", "left":"40%"}}/>
            </div>}
            {errorMessage && 
            <>
                <Row>
                    <Col xs={{ size: 4, offset: 4 }} className='mt-4'>
                        <img src="/images/icono-busqueda-01.svg" alt="icono de busqueda sin resultados" className='img-fluid' />
                    </Col>
                </Row>
                <Row>
                    <Col><h1 className='text-center text_3 fs-22 pt-3'>¡No encontramos ningún resultado!</h1></Col>
                </Row>
                <Row>
                    <Col className='text-start ps-4 pe-4 fs-12 ps-5 pt-2' style={{ "lineHeight": "22px" }}>
                        <p>{errorMessage}</p>
                    </Col>
                </Row>
            </>
            }
        </Container>
    );
};

export default TerminarCompraVendedor;