import React, { useState, Dispatch} from 'react';
import { Row, Col, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import {payCash} from '../../../api/purchase';
import TextField from '../TextField';
import {mostrarError} from '../../../reducer/config-reducer'
import { connect } from 'react-redux';
import {GenericObject} from '../../../types/types';
import Button from '../../components/Button'

const TipoPagoCash = (props:{mostrarError:Dispatch<GenericObject | null>}) => {
    const [numeroIdentidad, setNumeroIdentidad] = useState('');
    const [medioDePago, setmedioDePago] = useState('Seleccione un Lugar de Pago');
    const [dropdownOpenFecha, setDropdownOpenFecha] = useState(false);
    const opcionesDePago = [{ label: "Pago Fácil", value: "pagofacil" }, { label: "Rapipago", value: "rapipago" }]
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleDropDownFecha = () => {
        setDropdownOpenFecha((prevState) => !prevState);
    }

    const handlePagarButton = async() => {
        const res = await payCash(numeroIdentidad, medioDePago);
        if(res.result){
            navigate(`/pago-pendiente/cash/${res.checkoutData.paymentMethod}/${res.checkoutData.additionalCode}`)
        }else{
            props.mostrarError(res)
        }
    }

    const validateInput = async (name:string, value:string,  event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let error = '';

        if (event.target.required && event.target.value.trim() === '') {
            error = 'Complete el campo';
        } else if (event.target.value.length > parseInt(event.target.getAttribute('max') || '')) {
            error = `El campo debe tener como máximo ${event.target.getAttribute('max')} caracteres`;
        }

        setError(error)

        if (error !== '') {
            event.target.classList.add('has-error');
        } else {
            event.target.classList.remove('has-error');
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let str;
        var pattern = /^([0-9]+)?$/;
        if (pattern.test(event.target.value)) {
            str = event.target.value;
        } else {
            return;
        }
        setNumeroIdentidad(str)
    }
    return (
        <>
            <Row>
                <Col><h4 className='subtitles'>Completa los datos para el Código de Pago</h4></Col>
            </Row>
            <Row>
                <Col md={7}>
                    <TextField required={true} type='text' id='numeroIdentidad' label='Ingresa aquí tu DNI (sin puntos)' value={numeroIdentidad} errorMsg={error} handleInputChange={handleInputChange} validate={validateInput} focusEvent={() => {setError(''); setNumeroIdentidad('')}} />
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col md={7}>
                    <Dropdown isOpen={dropdownOpenFecha} toggle={handleDropDownFecha} direction='down'>
                        <DropdownToggle caret tag='div' className='select'>
                            <span>{medioDePago}</span>
                        </DropdownToggle>
                        <DropdownMenu tag={'ul'} className='options'>
                            {opcionesDePago.map((opcion, index) => {
                                return <li key={index} onClick={() => { setmedioDePago(opcion.value);handleDropDownFecha()  }}>{opcion.label}</li>;
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </Col>
            </Row>
            <Row>
                <Col className='steps-buttons text-center'>
                    <Button className='mb-3 bt-pagar-tipo-pago' onClick={handlePagarButton} disabled={numeroIdentidad === '' || medioDePago==='Seleccione un Lugar de Pago'}>GENERAR EL CODIGO DE PAGO</Button>
                </Col>
            </Row>
        </>
    );
};

  export default connect(
    null,
    { mostrarError }
  )(TipoPagoCash);