import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import '../../sass/aviso_seat.scss'
import { FormattedMessage } from 'react-intl';
const AvisoSeat = () => {
    const [modal, setModal] = useState(false);

    useEffect(()=>{
        //if(!localStorage.getItem('seatwidget-message')){
            setModal(true)
        //}
    },[])

    const toggle = () => {
        setModal(false);
        setTimeout(()=>{
            window.scrollTo(0, 170);
        },4000);
        //localStorage.setItem('seatwidget-message', "1")
    }
    return (
        <Modal className='aviso-seat' isOpen={modal} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>IMPORTANTE</ModalHeader>
            <ModalBody className='d-flex justify-content-between flex-column align-items-center'>
                <p><FormattedMessage id="aviso_seat" /></p>
                <Button onClick={toggle}>Continuar</Button>
            </ModalBody>
        </Modal>
    );
};

export default AvisoSeat;