import React, { useState, forwardRef, ForwardedRef, Dispatch, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateRangeIcon from '@mui/icons-material/DateRange';
import SearchIcon from '@mui/icons-material/Search';
import {
  Input,
  Button,
  Row,
  Col
} from 'reactstrap';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { fetchResultadosBusqueda } from '../../reducer/config-reducer';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EventsData } from '../../types/types';
import ResultadosBusqueda from './ResultadosBusqueda';
import moment from 'moment';
registerLocale('es', es);

interface InputProps {
  value?: string | number | string[];
  onClick?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface propiedades {
  fetchResultadosBusqueda: Dispatch<string>,
  resultadosBusqueda: EventsData
}
interface configObjectResultados {
  configReducer: {
    resultadosBusqueda: EventsData,
  }
}

const Buscador = (props: propiedades) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [textToSearch, setTextToSearch] = useState<string>('');
  const [buscandoResultados, setBuscandoResultados] = useState<boolean>(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const navigate = useNavigate();

  const datepickerHandler = (date: Date) => {
    setStartDate(date);
    const fecha = moment(date);
    const fechaFormateada = fecha.format('YYYY-MM-DD');

    navigate(`/eventos?d=${fechaFormateada}`)
  }

  const searchHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {

    setTextToSearch(evt.target.value);
    if (evt.target.value.length > 1) {
      props.fetchResultadosBusqueda(evt.target.value);
      evt.target.value !== '' ? setBuscandoResultados(true) : setBuscandoResultados(false)
    }
  }

  const navigateEventsHandler = () => {
    navigate(`/eventos?k=${textToSearch}`)
  }

  useEffect(() => {
    if (props.resultadosBusqueda) {
      setBuscandoResultados(false)
    }
  }, [props.resultadosBusqueda])

  const clickOutside = () => {
    setIsDatePickerOpen(false);
  }

  const handleKeyDown = (event:any) => {
    if (event.key === 'Enter') {
      navigateEventsHandler();
    }
  };

  const CustomInput = forwardRef(({ value, onClick, onChange }: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const handleIconClick = () => {
      if (isDatePickerOpen) {
        setIsDatePickerOpen(false);
      } else {
        onClick!();
        setIsDatePickerOpen(true);
      }
    };

    return (
      <>
        <input
          value={value}
          className="date-input"
          onClick={onClick}
          onChange={onChange}
          ref={ref}
        />
        <DateRangeIcon onClick={handleIconClick} className='date-range-icon' />
      </>
    );
  }
  );

  return (
    <>
      <Row className='w-100 d-flex justify-content-center'>
        <Col className='bg-white rounded d-flex align-items-center justify-content-center buscador'>
          <div className='ps-2'>
            <DatePicker onClickOutside={clickOutside} closeOnScroll={false} open={isDatePickerOpen}
              locale="es" selected={startDate} customInput={<CustomInput />} onChange={datepickerHandler} />
          </div>
          <div style={{ "flex": "1" }}>
            <Input placeholder='Buscar por Evento, Artista, Sala, ...' className='border-0 p-1' value={textToSearch} onChange={searchHandler} onKeyDown={handleKeyDown}/>
            <ResultadosBusqueda buscandoResultados={buscandoResultados} setTextToSearch={setTextToSearch} />
          </div>
          <div className='pe-2'>
            <Button color='link' className='p-0 btn-search' onClick={navigateEventsHandler} disabled={textToSearch === ''} style={{ "color": "#333" }}><SearchIcon /></Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='text-white text-center home-subtitle'>
          <h3>¡Busca el Evento y consigue tus Lugares!</h3>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: configObjectResultados) => {
  return {
    resultadosBusqueda: state.configReducer.resultadosBusqueda,
  }
}
export default connect(
  mapStateToProps,
  { fetchResultadosBusqueda }
)(Buscador);
