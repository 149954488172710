import React, { Dispatch, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Button
} from 'reactstrap';
import { connect } from 'react-redux';
import { eventoSeleccionado, GenericObject } from '../../../types/types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FechaYHoraSimple from './FechaYHoraSimple';
import SeleccionarFechaYHora from './SeleccionarFechaYHora';
import InfoGeneral from './InfoGeneral';
import UbicacionFotos from './UbicacionFotos';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import TwitterIcon from '../../../utilities/TwitterIcon';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { parseJWt } from '../../../utilities/utilities';
import '../../sass/evento_seleccionado/paso_uno.scss';
import SeleccionarSoloFecha from './SeleccionarSoloFecha';
import Precios from './Precios';
import { comprarPasoDos } from '../../../api/purchase'
import { useNavigate } from 'react-router-dom';
import { getLangValue } from '../../../api/config';
import { Carousel } from 'react-responsive-carousel';
import EventoFotos from './EventoFotos';
import TextField from '../TextField';
import { setCard } from '../../../reducer/events-reducer';
import { mostrarError } from '../../../reducer/config-reducer';
interface propiedades {
    eventoSeleccionado: eventoSeleccionado;
    idVendedor: string,
    mostrarError:Dispatch<GenericObject | null>,
    setCard: (cardNumber: string | null) => void;
}

type configObjectEventos = {
    eventsReducer: {
        eventoSeleccionado: eventoSeleccionado;
        idVendedor: string;
    }
}



const PasoUnoEvento = (props: propiedades) => {
    const [horaSeleccionada, setHoraSeleccionada] = useState('')
    const navigate = useNavigate();
    const currentUrl = `${getLangValue("front")}/${window.location.pathname}`;
    const shareButtonProps = {
        url: currentUrl,
        resetButtonStyle: false
    };
    const { eventoSeleccionado, idVendedor } = props;
    const [referrer, setReferrer] = useState<string | null>('');
    const [cardNumber, setCardNumber] = useState<string | null>(null);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (horaSeleccionada && localStorage.getItem('token')) {
            if (horaSeleccionada !== parseJWt(localStorage.getItem('token')!).sessionEventId) {
                localStorage.removeItem('token')
            }
        }
        if (localStorage.getItem('referrer')) {
            setReferrer(localStorage.getItem('referrer'));
        }
    }, [horaSeleccionada])

    const nextStep = (token: string) => {
        localStorage.setItem('token', token);
        const hash = window.location.pathname.split("/")[2];
        navigate(`/evento/${hash}/step/2`)
    }

    const buyTicketHandler = async () => {
        if (eventoSeleccionado.sessions && eventoSeleccionado.sessions.length === 1 && eventoSeleccionado.sessions[0].hours.length === 1) {
            try {
                const res = await comprarPasoDos(eventoSeleccionado.sessions[0].hours[0].code, idVendedor, referrer, cardNumber);
                if (res) {
                    localStorage.removeItem('referrer')
                    nextStep(res.token)
                    props.setCard(cardNumber);
                }   
            } catch (error: any) {
                props.mostrarError({mensajeAMostrar: error!.message});
            }
        } else {
            try {
                const res = await comprarPasoDos(horaSeleccionada, idVendedor, referrer, cardNumber);
                if (res) {
                    localStorage.removeItem('referrer')
                    nextStep(res.token)
                    props.setCard(cardNumber);
                }
            } catch (error: any) {
                props.mostrarError({mensajeAMostrar: error!.message});
            }
        }
    }
    const volverAtras = () => {
        navigate(`/`)
    }
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let str:string
        var pattern = /^([0-9]+)?$/;
            if (pattern.test(event.target.value)) {
                str = event.target.value;
            }else{
                return;
            }
        
        setCardNumber(str);
    }

    const formattedInfoPrices = eventoSeleccionado.infoPrices.replace(/\n/g, '<br />');
    return (
        <>
            {eventoSeleccionado.image && eventoSeleccionado.eventImages.length === 1 &&
                <Row>
                    <Col className='p-0 paso-uno-image-wrapper mb-2 d-flex justify-content-center'>
                        <div style={{ "position": "relative" }}>
                            <img src={eventoSeleccionado.mobileImage!} alt="" className='img-fluid' />
                            {eventoSeleccionado.status === '0' &&
                                <div className='position-absolute w-100' style={{ "top": "50%", "marginTop": "-3em" }}>
                                    <img src="/images/agotado.png" alt="agotado" className='w-100' />
                                </div>
                            }
                        </div>
                    </Col>
                </Row>}
                {eventoSeleccionado.eventImages.length > 1 && <Row>
                        <Col className='mb-2 mt-2'>
                            <Carousel autoPlay showArrows={true} showThumbs={false} showStatus={false} dynamicHeight={true} infiniteLoop>
                            {eventoSeleccionado.eventImages.map((media, index) => {
                                return (
                                    <div key={index}>
                                        <img src={media.url_mobile} alt={media.title} />
                                        {eventoSeleccionado.status === '0' &&
                                            <div className='position-absolute w-100' style={{ "top": "50%", "marginTop": "-3em" }}>
                                                <img src="/images/agotado.png" alt="agotado" className='w-100' />
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                            </Carousel>
                        </Col>
                    </Row>}
            {!eventoSeleccionado.mobileImage && <Row>
                <Col> <p className="h4 titulo_evento_en_paso text_2" dangerouslySetInnerHTML={{ __html: eventoSeleccionado.title }} />
                    <hr className='hr-info' />
                </Col>
            </Row>}
            <Row>
                <Col>
                    <div className='rounded-circle abajo-icon float-end mt-2  animate__animated animate__bounce'><KeyboardArrowDownIcon /></div>
                </Col>
            </Row>
            <Row className='compra-paso-uno'>
                <Col className='text-start event-data'>

                    {function () {
                        if (eventoSeleccionado.sessions && eventoSeleccionado.sessions.length === 1 && eventoSeleccionado.sessions[0].hours.length === 1) {
                            return (<>
                                <h4 className='mt-2 subtitle'>Fecha y Hora</h4>
                                <FechaYHoraSimple fecha={eventoSeleccionado.sessions[0].title} hora={eventoSeleccionado.sessions[0].hours[0].hour} status={eventoSeleccionado.status} showBadgetAgotado={!eventoSeleccionado.image} />
                                <hr className='hr-info' />
                            </>)
                        }
                    }()}
                    <h4 className='mt-2 subtitle'>Lugar</h4>
                    <UbicacionFotos
                        lugar={eventoSeleccionado.place}
                        address={eventoSeleccionado.address ? eventoSeleccionado.address : null}
                        map={eventoSeleccionado.map}
                        medialist={eventoSeleccionado.mediaList}
                        location={eventoSeleccionado.location}
                    />
                    <hr className='hr-info' />
                    {(function () {
                        if (eventoSeleccionado.infoPrices && eventoSeleccionado.infoPrices !== '') {
                            return (<>
                                <h4 className='mt-2 subtitle'>Precios</h4>
                                <Precios precios={formattedInfoPrices} />
                                <hr className='hr-info' />
                            </>);
                        }
                    })()}
                    {eventoSeleccionado.sessions && <>
                        <h4 className='mt-2 subtitle'>Info General</h4>
                        <InfoGeneral
                            duration={eventoSeleccionado.duration}
                            classification={eventoSeleccionado.classification}
                        /></>}
                    {eventoSeleccionado.sessions && <Row className='row-info'>
                        {eventoSeleccionado.status !== "0" &&
                            <div className='buy-tickets'>
                                {(function () {
                                    if (eventoSeleccionado.sessions && eventoSeleccionado.sessions.length > 1 && !eventoSeleccionado.oneSessionPerDay) {
                                        return (<>
                                            <SeleccionarFechaYHora sesiones={eventoSeleccionado.sessions} status={eventoSeleccionado.status} horaSeleccionada={horaSeleccionada} setHoraSeleccionada={setHoraSeleccionada} />
                                        </>)
                                    } else if (eventoSeleccionado.sessions && ((eventoSeleccionado.sessions.length > 1 && eventoSeleccionado.oneSessionPerDay) || (eventoSeleccionado.sessions.length === 1 && eventoSeleccionado.sessions[0].hours.length > 1))) {
                                        return (<> <SeleccionarSoloFecha sesiones={eventoSeleccionado.sessions} status={eventoSeleccionado.status} horaSeleccionada={horaSeleccionada} setHoraSeleccionada={setHoraSeleccionada} /></>)
                                    }
                                })()}
                                {eventoSeleccionado.requireCreditCard && 
                                    <>
                                    <h4 className='subtitles'>Ingresa el Número de la Tarjeta con la que vas a Pagar</h4>
                                    <TextField required={true} type='text' id='numeroTarjeta' label='Sólo números, sin espacios' value={cardNumber ? cardNumber : ""} errorMsg={error} handleInputChange={handleInputChange} focusEvent={() => { setError(''); setCardNumber('') }} />
                                    </>
                                }
                                <Button disabled={(horaSeleccionada === "" && !(eventoSeleccionado.sessions && eventoSeleccionado.sessions.length === 1 && eventoSeleccionado.sessions[0].hours.length === 1))|| (eventoSeleccionado.requireCreditCard && (cardNumber === null || cardNumber === "" || cardNumber.length < 6))} onClick={buyTicketHandler}>{eventoSeleccionado.paymentType === 1 ? 'COMPRA' : 'OBTIENE'} TUS ENTRADAS &gt;</Button>
                            </div>}
                        {eventoSeleccionado.status === "0" && <Button className='back_2 p-3 ps-4 pe-4 mt-4 mb-2' onClick={volverAtras}> IR AL INICIO </Button>}
                    </Row>}
                    {eventoSeleccionado.sessions && !eventoSeleccionado.description && <><hr className='hr-info' /></>}
                    {eventoSeleccionado.description && eventoSeleccionado.description !== '' && <>
                        <hr className='hr-info' />
                        <h4 className='mt-2 subtitle'>Más Info</h4>
                        <Row className='row-info'>
                            <Col className='ps-4 mb-4'><div dangerouslySetInnerHTML={{ __html: eventoSeleccionado.description }} className='paso-description'></div></Col>
                        </Row>
                    </>}
                    {eventoSeleccionado.eventImagesVideos.length > 0 && <EventoFotos medialist= {eventoSeleccionado.eventImagesVideos}/>}
                    <Row>
                        <Col className='text-center share-event'>
                            Recomienda este Evento
                        </Col>
                    </Row>
                    <Row>
                        <Col className='d-flex justify-content-center pb-4 social-share-link-icons'>
                            <FacebookShareButton {...shareButtonProps}>
                                <FacebookIcon />
                            </FacebookShareButton>
                            <TwitterShareButton  {...shareButtonProps}>
                                <TwitterIcon />
                            </TwitterShareButton>
                            <WhatsappShareButton {...shareButtonProps}>
                                <WhatsAppIcon />
                            </WhatsappShareButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        eventoSeleccionado: state.eventsReducer.eventoSeleccionado,
        idVendedor: state.eventsReducer.idVendedor
    }
}

export default connect(
    mapStateToProps,
    {setCard, mostrarError}
)(PasoUnoEvento);
