import React from 'react';
import { connect } from 'react-redux';
import {datosCompra} from '../../../types/types';
import DateRangeIcon from '@mui/icons-material/DateRange';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

type configObjectDatosComprados = {
    eventsReducer: {
        detalleCompra:datosCompra
    }
  }

const TituloEvento = ({detalleCompra, paso}:{detalleCompra:datosCompra, paso:number}) => {
    return (
        <>
            <div><p className="h4 titulo_evento_en_paso text_2" dangerouslySetInnerHTML={{ __html: detalleCompra.eventName }} /></div>
            <div className='d-flex justify-content-center time-date-wrapper'>
                <div className='d-flex text-center'>
                    <DateRangeIcon />
                    <div className=''>{detalleCompra.eventDate}</div>
                </div>
            </div>
            <div className='d-flex justify-content-center time-date-wrapper'>
                <div className='d-flex text-center'>
                    <WatchLaterOutlinedIcon />
                    <div className='text-center'>{detalleCompra.eventHour}</div>
                </div>
            </div>
            {paso > 2 && <div className='d-flex location-wrapper'>
                <div className='d-flex text-center'>
                    <div className=''><LocationOnOutlinedIcon />{detalleCompra.address}</div>
                </div>
            </div>}
            <div className='mb-2'></div>
        </>
    );
};

const mapStateToProps = (state: configObjectDatosComprados) => {
    return {
        detalleCompra: state.eventsReducer.detalleCompra
    }
}
export default connect(
    mapStateToProps,
)(TituloEvento);
