import { AttachMoney } from '@mui/icons-material';
import React from 'react';import {
    Row,
    Col,
} from 'reactstrap';

const Precios = (propiedades: { precios: string }) => {
    return (
        <Row className='precios row-info'>
            <Col className='d-flex align-items-center'>
                <AttachMoney/>
                <div className='ms-2 align-items-center' style={{"flex":"1"}}>
                    <p dangerouslySetInnerHTML={{ __html: propiedades.precios }}></p>
                </div>
            </Col>
        </Row>
    )
}

export default Precios;