import React, { useState, useEffect } from 'react';
import '../sass/home.scss';
import { isMobile } from "react-device-detect";
import { getBackgroundImage } from '../../api/home';
import { connect } from 'react-redux';
import {configObjectTelefono} from '../../types/types'
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import Buscador from '../components/Buscador';
import 'animate.css';

type resImage = {

  bg_desktop_image: string,
  bg_mobile_image: string,
  footer_message_mobile: string

}

interface propiedades {
  telefonoAyuda: string
}

const Home = (props:propiedades) => {
  const [desktopImage, setDesktopImage] = useState('');
  const [mobileImage, setMobileImage] = useState('');
  const [footerMessage, setFooterMessage] = useState('');

  useEffect(() => {
    const getImages = async () => {
      const res: resImage = await getBackgroundImage();
      setDesktopImage(res.bg_desktop_image);
      setMobileImage(res.bg_mobile_image);
      setFooterMessage(res.footer_message_mobile)
    }
    getImages();
  }, [])

  return (
    <div className='home d-flex align-items-center position-relative' style={{ "backgroundImage": isMobile ? `url(${mobileImage})` : `url(${desktopImage})` }}>
      <Container className='mb-5'>
        <Row className='mb-2'>
          <Col xs={{ size: 8, offset: 2 }} md={{ size: 4, offset: 4 }}> <img src="/images/logo.png" alt="logo entrada web" className='logo img-fluid mb-2 animate__animated animate__bounce' /> </Col>
        </Row>
        <div className='buscador-wrapper mt-4'>
          <Buscador />
        </div>
      </Container>
      <div className='ayuda-online position-absolute bottom-0 w-100 text-end'>
        <div className='text-center text-white pb-3' dangerouslySetInnerHTML={{ __html: footerMessage }} />
      </div>
    </div>
  );
};


const mapStateToProps = (state: configObjectTelefono) => {
  return {
    telefonoAyuda: state.configReducer.telefonoAyuda
  }
}
export default connect(
  mapStateToProps,
)(Home);

