import React from 'react';import {
    Row,
    Col,
} from 'reactstrap';
import DateRangeIcon from '@mui/icons-material/DateRange';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';

const FechaYHoraSimple = (propiedades: { fecha: string, hora: string, status: string, showBadgetAgotado: boolean }) => {
    return (
        <>
            <Row className='fecha-hora-simple'>
                <Col xs={6} className='d-flex'>
                    <DateRangeIcon className='me-2' />
                    <div className='d-flex align-items-center'>{propiedades.fecha}</div>
                </Col>
                <Col xs={6}><WatchLaterOutlinedIcon className='me-2' />
                    {propiedades.hora}
                </Col>
            </Row>
            <Row>
                <Col xs={12} className='d-flex'>
                    {propiedades.status === "0" && propiedades.showBadgetAgotado && <><div className="badge w-100 badge-agotado">AGOTADO</div></>}
                </Col>
            </Row>
        </>
    )
}

export default FechaYHoraSimple;